import { useEffect, useState } from "react";
import { Container, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { copy, qr } from "../../../assests/Icons/icon";
import {
  copyToClipBoard,
  getCurrentUserData,
  notifyBugsnagError,
  openQrCode,
  removeDashed,
  showToast,
} from "../../../common/utils/Functions";
import transformDataToTableFormat from "../../../common/utils/convertOrgData";
import DashboardLayout from "../../../components/layouts/DashboardLayout";
import PageHeading from "../../../components/pageHeading";
import ExcelForOrgDetails from "../../../components/reactExcel/ReactXlsx";
import ReactLoader from "../../../components/reactLoader/ReactLoader";
import ReactTable from "../../../components/reactTable/ReactTable";
import useFetch from "../../../hooks/useFetch";
import {
  propertyEmployeeListType,
  propertyRoomListType,
  propertyServiceListType,
  propertyType,
} from "../../../types/manageProperty";
import { orgExcelType } from "../../../types/organization";
import styles from "../qrCodeAndLink/qrCodeAndLink.module.scss";

interface orgDetails {
  id?: number | null;
  name: string;
  qrCode: React.ReactElement;
  url: React.ReactElement;
}

const QRCodeAndLinks = () => {
  const { uuid } = useParams();
  const data = getCurrentUserData(uuid as string);
  const [orgDetail, setOrgDetail] = useState<any>([]);
  const [organizationExcelData, setOrganizationExcelData] = useState<any[]>([]);
  /**
   * @Data_list states  these below states are used to store data which will render in React-table
   * 1. OrganizationView  :- Array of organization data list
   * 2. PropertyList :- Array of property data list
   * 3. ServiceList :- Array of service data list
   * 2. EmployeesList :- Array of employees data list
   * 2. RoomList :- Array of room data list
   *
   */
  const [searchValue, setSearchValue] = useState("");

  const [organizationList, setOrganizationList] = useState<orgDetails[]>([]);
  const [propertyList, setPropertyList] = useState<propertyType[]>();
  const [serviceList, setServiceList] = useState<propertyServiceListType[]>();
  const [employeesList, setEmployeesList] =
    useState<propertyEmployeeListType[]>();
  const [roomList, setRoomList] = useState<propertyRoomListType[]>();

  /**
   * @Pagination states
   * 1. pageLimit    :- store limit of data that will render on current page
   * 2. totalRecords :- store total number of data in partners api
   * 3. currentPage  :- store current page number
   */
  const [pageLimit, setPageLimit] = useState<number>(10);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);

  /** For property service pagination */
  const [pageLimitService, setPageLimitService] = useState<number>(10);
  const [totalRecordsService, setTotalRecordsService] = useState<number>(0);
  const [currentPageService, setCurrentPageService] = useState<number>(1);

  /**For property employee pagination */
  const [pageLimitEmployee, setPageLimitEmployee] = useState<number>(10);
  const [totalRecordsEmployee, setTotalRecordsEmployee] = useState<number>(0);
  const [currentPageEmployee, setCurrentPageEmployee] = useState<number>(1);

  /**For property employee pagination */
  const [pageLimitRoom, setPageLimitRoom] = useState<number>(10);
  const [totalRecordsRoom, setTotalRecordsRoom] = useState<number>(0);
  const [currentPageRoom, setCurrentPageRoom] = useState<number>(1);
  /**
   * @Api_Definition variables
   * 1. organizationListApi  :- Constant to handle list all organization data
   * 2. propertyListApi :- Constant to handle list all property data
   * 3. serviceListApi :- Constant to handle list all service data
   * 4. employeeListApi :- Constant to handle list all employee data
   * 5. roomListApi :- Constant to handle list all room data
   */

  /** ------ @viewOrganizationApi ------- */
  const [
    viewOrganizationApi,
    {
      response: viewOrganization,
      loading: organizationLoading,
      error: organizationError,
    },
  ] = useFetch(`/organization/view/${data?.uuid}`);

  /**----------2. @propertyListApi ------- */
  const [
    propertyListApi,
    {
      response: propertyResponse,
      loading: propertyLoading,
      error: propertyError,
    },
  ] = useFetch("/property/list", { method: "post" });

  /**----------3. @serviceListApi ------- */
  const [
    serviceListApi,
    { response: serviceResponse, loading: serviceLoading, error: serviceError },
  ] = useFetch("/propertyservices/list", { method: "post" });

  /**----------4. @employeeListApi ------- */
  const [
    employeeListApi,
    {
      response: employeeResponse,
      loading: employeeLoading,
      error: employeeError,
    },
  ] = useFetch("/propertyemployees/list", { method: "post" });

  /**----------5. @roomListApi ------- */
  const [
    roomListApi,
    { response: roomResponse, loading: roomLoading, error: roomError },
  ] = useFetch("/propertyrooms/list", { method: "post" });

  /**
   * @Call all organization data  using @viewProfileApi constant
   */
  useEffect(() => {
    const timer = setTimeout(() => {
      viewOrganizationApi();
    }, 500);
    return () => clearTimeout(timer);
  }, []);

  /**
   * @handle_Response of above api call using @listApi constant
   * => in this effect
   * 1. @setTotalRecords setState updates with total records
   * 2. @setPropertyList  setState updates with incoming property-data
   * 3. @if_statement    to handle error if api not responded
   */
  useEffect(() => {
    if (viewOrganization) {
      const orgDetails: orgDetails[] = [
        {
          // id: viewOrganization?.data?.id,
          name: viewOrganization?.data?.name,
          qrCode: (
            <span
              onClick={() => {
                openQrCode(viewOrganization?.data?.qr_code as string);
              }}
            >
              {qr}
            </span>
          ),
          url: (
            <span
              onClick={() => {
                copyToClipBoard(viewOrganization?.data?.copy_link as string);
              }}
            >
              {copy}
            </span>
          ),
        },
      ];
      setOrganizationList(orgDetails);
    }
    if (organizationError) {
      showToast(organizationError.message as string, "error");
    }
  }, [viewOrganization, organizationError]);

  /**
   * @Call all property data using @listApi constant
   */
  useEffect(() => {
    const timer = setTimeout(() => {
      try {
        propertyListApi({
          start: (currentPage - 1) * pageLimit,
          limit: pageLimit,
          search: searchValue,
        });
      } catch (e: any) {
        notifyBugsnagError(e, {
          api: "propertyListApi",
        });
      }
    }, 500);
    return () => clearTimeout(timer);
  }, [searchValue, currentPage, pageLimit]);

  /**
   * @handle_Response of above api call using @listApi constant
   * => in this effect
   * 1. @setTotalRecords setState updates with total records
   * 2. @setPropertyList  setState updates with incoming property-data
   * 3. @if_statement    to handle error if api not responded
   */
  useEffect(() => {
    if (propertyResponse) {
      setTotalRecords(propertyResponse.data?.totalRecords);
      const updatedPropertyList = propertyResponse.data.list?.map(
        (item: propertyType) => ({
          ...item,
          url: (
            <span
              onClick={() => {
                copyToClipBoard(item?.copy_link as string);
              }}
            >
              {" "}
              {copy}{" "}
            </span>
          ),
          qrCode: (
            <span
              onClick={() => {
                openQrCode(item?.qr_code as string);
              }}
            >
              {" "}
              {qr}{" "}
            </span>
          ),
        })
      );
      setPropertyList(updatedPropertyList);
    }
    if (propertyError) {
      showToast(propertyError.message as string, "error");
    }
  }, [propertyResponse, propertyError]);

  /** useEffect for propertyservice list */
  useEffect(() => {
    const timer = setTimeout(() => {
      try {
        serviceListApi({
          start: (currentPageService - 1) * pageLimitService,
          limit: pageLimitService,
          search: searchValue,
          property_uuid: "",
        });
      } catch (e: any) {
        notifyBugsnagError(propertyError, {
          api: "serviceListApi",
        });
      }
    }, 500);
    return () => clearTimeout(timer);
  }, [searchValue, currentPageService, pageLimitService]);
  /**
   * @handle_Response of above api call using @listApi constant
   * => in this effect
   * 1. @setTotalRecords setState updates with total records
   * 2. @setPropertyServiceList  setState updates with incoming property-service-data
   * 3. @if_statement    to handle error if api not responded
   */
  useEffect(() => {
    if (serviceResponse) {
      setTotalRecordsService(serviceResponse.data?.totalRecords);
      const updatedServiceList = serviceResponse?.data?.list?.map(
        (item: propertyServiceListType) => ({
          ...item,
          name: item?.service_details?.name,
          PropName: item?.property_detail?.name,
          url: (
            <span
              onClick={() => {
                copyToClipBoard(item?.copy_link as string);
              }}
            >
              {" "}
              {copy}{" "}
            </span>
          ),
          qrCode: (
            <span
              onClick={() => {
                openQrCode(item?.qr_code as string);
              }}
            >
              {" "}
              {qr}{" "}
            </span>
          ),
        })
      );
      setServiceList(updatedServiceList);
    }
    if (serviceError) {
      showToast(serviceError.message as string, "error");
    }
  }, [serviceResponse, serviceError]);

  /** useEffect for propertyemployee list */
  useEffect(() => {
    const timer = setTimeout(() => {
      try {
        employeeListApi({
          start: (currentPageEmployee - 1) * pageLimitEmployee,
          limit: pageLimitEmployee,
          search: searchValue,
          // property_uuid: "",
        });
      } catch (e: any) {
        notifyBugsnagError(propertyError, {
          api: "employeeListApi",
        });
      }
    }, 500);
    return () => clearTimeout(timer);
  }, [searchValue, currentPageEmployee, pageLimitEmployee]);
  /**
   * @handle_Response of above api call using @listApi constant
   * => in this effect
   * 1. @setTotalRecords setState updates with total records
   * 2. @setPropertyEmployeeList  setState updates with incoming property-employee-data
   * 3. @if_statement    to handle error if api not responded
   */
  useEffect(() => {
    if (employeeResponse) {
      setTotalRecordsEmployee(employeeResponse.data?.totalRecords);
      const updatedEmployeeList = employeeResponse.data.list?.map(
        (item: propertyEmployeeListType) => ({
          ...item,
          PropName: item?.property_detail?.name,
          url: (
            <span
              onClick={() => {
                copyToClipBoard(item?.copy_link as string);
              }}
            >
              {" "}
              {copy}{" "}
            </span>
          ),
          qrCode: (
            <span
              onClick={() => {
                openQrCode(item?.qr_code as string);
              }}
            >
              {" "}
              {qr}{" "}
            </span>
          ),
        })
      );
      setEmployeesList(updatedEmployeeList);
    }
    if (employeeError) {
      showToast(employeeError.message as string, "error");
    }
  }, [employeeResponse, employeeError]);

  /** useEffect for propertyroom list */
  useEffect(() => {
    const timer = setTimeout(() => {
      try {
        roomListApi({
          start: (currentPageRoom - 1) * pageLimitRoom,
          limit: pageLimitRoom,
          search: searchValue,
          // property_uuid: "",
        });
      } catch (e: any) {
        notifyBugsnagError(e, {
          api: "roomListApi",
        });
      }
    }, 500);
    return () => clearTimeout(timer);
  }, [searchValue, currentPageRoom, pageLimitRoom]);
  /**
   * @handle_Response of above api call using @listApi constant
   * => in this effect
   * 1. @setTotalRecords setState updates with total records
   * 2. @setPropertyRoomList  setState updates with incoming property-room-data
   * 3. @if_statement    to handle error if api not responded
   */
  useEffect(() => {
    if (roomResponse) {
      setTotalRecordsRoom(roomResponse.data?.totalRecords);
      const updatedEmployeeList = roomResponse.data.list?.map(
        (item: propertyRoomListType) => ({
          ...item,

          PropName: item?.property_detail?.name,
          url: (
            <span
              onClick={() => {
                copyToClipBoard(item?.copy_link as string);
              }}
            >
              {" "}
              {copy}{" "}
            </span>
          ),
          qrCode: (
            <span
              onClick={() => {
                openQrCode(item?.qr_code as string);
              }}
            >
              {" "}
              {qr}{" "}
            </span>
          ),
        })
      );
      setRoomList(updatedEmployeeList);
    }
    if (roomError) {
      showToast(roomError.message as string, "error");
    }
  }, [roomResponse, roomError]);

  const orgHeading = [
    // { key: "id", label: "Organization ID" },
    { key: "name", label: "Orgainization Name" },
    { key: "qrCode", label: "QR Code", textAlign: "center" },
    { key: "url", label: "URL", textAlign: "center" },
  ];

  const propertyHeading = [
    { key: "id", label: "Property ID" },
    { key: "name", label: "Property Name" },
    { key: "qrCode", label: "QR Code", textAlign: "center" },
    { key: "url", label: "URL", textAlign: "center" },
  ];
  const serviceHeading = [
    { key: "name", label: "Service Name" },
    { key: "PropName", label: "Property Name" },
    { key: "qrCode", label: "QR Code", textAlign: "center" },
    { key: "url", label: "URL", textAlign: "center" },
  ];
  const employeeHeading = [
    { key: "name", label: "Employee Name" },
    { key: "email", label: "Employee Email" },
    { key: "PropName", label: "Property Name" },
    { key: "qrCode", label: "QR Code", textAlign: "center" },
    { key: "url", label: "URL", textAlign: "center" },
  ];
  const roomHeading = [
    { key: "id", label: "Room ID" },
    { key: "name", label: "Room Name" },
    { key: "PropName", label: "Property Name" },
    { key: "qrCode", label: "QR Code", textAlign: "center" },
    { key: "url", label: "URL", textAlign: "center" },
  ];
  const columnWidths = [30, 30, 85, 85, 85, 85, 85, 85, 85, 85, 85, 85];

  /** Api definition */
  const [
    orgDetailsExcelListApi,
    {
      response: orgDetailsExcelListResponse,
      loading: orgDetailsExcelListLoading,
      error: orgDetailsExcelListError,
    },
  ] = useFetch(`/common/get-qr-code-details`, {
    method: "post",
  });

  const [
    orgDetailsExcelApi,
    {
      response: orgDetailsExcelResponse,
      loading: orgDetailsExcelLoading,
      error: orgDetailsExcelError,
    },
  ] = useFetch(`/organization/export-org-details/${data.uuid}`);

  /** handle to update org-excel data  */
  const handleOrgExcelData = () => {
    try {
      orgDetailsExcelApi({
        type: "qr_organization",
        uuid: data.uuid,
      });
    } catch (e: any) {
      notifyBugsnagError(e, {
        api: "orgDetailsExcelApi",
      });
    }
  };

  useEffect(() => {
    if (orgDetailsExcelResponse) {
      // const propertyDataRes =
      //   orgDetailsExcelResponse?.data?.organization_details?.properties_details;
      const orgDataRes = orgDetailsExcelResponse?.data?.org;
      const orgExcelRes = [
        {
          // id: orgDataRes?.organization_details?.id.toString()
          //   ? orgDataRes?.organization_details?.id.toString()
          //   : removeDashed,
          name: orgDataRes?.organization_details?.name
            ? orgDataRes?.organization_details?.name
            : removeDashed,

          status: orgDataRes?.is_active ? "Active" : "In Active",
          admin_name: orgDataRes?.first_name
            ? orgDataRes?.first_name + orgDataRes?.last_name
            : removeDashed,
          admin_email: orgDataRes?.email ? orgDataRes?.email : removeDashed,
          admin_no: orgDataRes?.phone_number
            ? `+${orgDataRes?.phone_code_id} ${orgDataRes?.phone_number}`
            : removeDashed,
          url: orgDataRes?.organization_details?.url_code
            ? orgDataRes?.organization_details?.url_code
            : removeDashed,
          fee:
            "$" + orgDataRes?.organization_details?.top_tipper_fees?.toFixed(2),
          theme_color: orgDataRes?.organization_details?.theme_color
            ? orgDataRes?.organization_details?.theme_color
            : removeDashed,
          font_color: orgDataRes?.organization_details?.font_color
            ? orgDataRes?.organization_details?.font_color
            : removeDashed,
          qr_code: orgDataRes?.organization_details?.qr_code
            ? orgDataRes?.organization_details?.qr_code
            : removeDashed,
          link: orgDataRes?.organization_details?.copy_link
            ? orgDataRes?.organization_details?.copy_link
            : removeDashed,
          stripe_id: orgDataRes?.organization_details?.stripe_connect_account_id
            ? orgDataRes?.organization_details?.stripe_connect_account_id
            : removeDashed,
        },
      ];
      setOrgDetail(orgExcelRes);
      const result = [
        {
          properties:
            orgDetailsExcelResponse?.data?.org?.organization_details?.properties_details?.map(
              (property: any) => [
                property?.id.toString() ? property?.id.toString() : removeDashed,
                property?.name ? property?.name : removeDashed,
                property?.copy_link ? property?.copy_link : removeDashed,
                property?.qr_code ? property?.qr_code : removeDashed,
              ]
            ),
        },
        {
          services_rooms: [
            ...orgDetailsExcelResponse?.data?.services?.map((service: any) => [
              service.property_detail?.name
                ? service.property_detail?.name
                : removeDashed,
              "Service",
              service.id?.toString() ? service.id?.toString() : removeDashed,
              service?.services_details?.name
                ? service?.services_details?.name
                : removeDashed,
              service?.copy_link ? service?.copy_link : removeDashed,
              service?.qr_code ? service?.qr_code : removeDashed,
            ]),
            ...orgDetailsExcelResponse?.data?.rooms?.map((room: any) => [
              room.property_detail?.name ? room.property_detail?.name : removeDashed,
              "Room",
              room.id?.toString() ? room.id?.toString() : removeDashed,
              room.name ? room.name : removeDashed,
              room?.copy_link ? room?.copy_link : removeDashed,
              room?.qr_code ? room?.qr_code : removeDashed,
            ]),
          ],
        },

        {
          employees: orgDetailsExcelResponse?.data?.employees?.map(
            (employee: any) => [
              employee.property_detail?.name
                ? employee.property_detail?.name
                : removeDashed,
              employee?.id.toString() ? employee?.id.toString() : removeDashed,
              employee?.name ? employee?.name : removeDashed,
              employee?.copy_link ? employee?.copy_link : removeDashed,
              employee?.qr_code ? employee?.qr_code : removeDashed,
            ]
          ),
        },
      ];
      // const orgPropDetails = propertyDataRes?.map((property: any) => {
      //   return {
      //     id: property?.id ? property?.id : removeDashed,
      //     name: property?.name ? property?.name : removeDashed,
      //     email: property?.email ? property?.email : removeDashed,
      //     link: property?.copy_link ? property?.copy_link : removeDashed,
      //     qr_code: property?.qr_code ? property?.qr_code : removeDashed,
      //     address: `United States`,
      //     is_active: property?.is_active,
      //     property_services_details: property?.property_services_details?.map(
      //       (service: any) => {
      //         return {
      //           id: service?.service_id ? service?.service_id : removeDashed,
      //           name: service?.services_details?.name
      //             ? service?.services_details?.name
      //             : removeDashed,
      //           url_code: service?.copy_link ? service?.copy_link : removeDashed,
      //           qr_code: service?.qr_code ? service?.qr_code : removeDashed,
      //         };
      //       }
      //     ),
      //     property_rooms_details: property?.property_rooms_details?.map(
      //       (room: any) => {
      //         return {
      //           id: room?.id ? room?.id : removeDashed,
      //           name: room?.name ? room?.name : removeDashed,
      //           link: room?.copy_link ? room?.copy_link : removeDashed,
      //           qr_code: room?.qr_code ? room?.qr_code : removeDashed,
      //         };
      //       }
      //     ),
      //     property_employees_details: property?.property_employees_details?.map(
      //       (employee: any) => {
      //         return {
      //           id: employee?.id ? employee?.id : removeDashed,
      //           name: employee?.name ? employee?.name : removeDashed,
      //           phone: employee?.phone_number ? employee?.phone_number : removeDashed,
      //           email: employee?.email ? employee?.email : removeDashed,
      //           link: employee?.copy_link ? employee?.copy_link : removeDashed,
      //           qr_code: employee?.qr_code ? employee?.qr_code : removeDashed,
      //         };
      //       }
      //     ),
      //   };
      // });
      setOrganizationExcelData([...result]);
    }
  }, [orgDetailsExcelResponse, orgDetailsExcelError]);

  return (
    <DashboardLayout>
      {organizationLoading || roomResponse === undefined ? (
        <ReactLoader />
      ) : (
        <Container className={styles.Container}>
          <div className={styles.dash_page}>
            <PageHeading
              heading="QR Codes & Links"
              subHeading="Here you can see all your QR codes and Url’s."
            >
              <ExcelForOrgDetails
                isQrCodes={true}
                orgDetails={orgDetail}
                setOrgDetail={setOrgDetail}
                setExcelData={setOrganizationExcelData}
                columnWidths={columnWidths}
                tables={transformDataToTableFormat(organizationExcelData, true)}
                buttonTitle="Export CSV"
                loading={orgDetailsExcelLoading}
                onClick={handleOrgExcelData}
              />
              {/* <ThemeButton>Export CSV</ThemeButton> */}
            </PageHeading>
          </div>

          <Row>
            <ReactTable
              headings={orgHeading}
              data={organizationList || []}
              actionColumn={false}
              pagination={false}
              loading={organizationLoading || !organizationList}
            >
              <div className={styles.org}>
                <p>Organization</p>
              </div>
            </ReactTable>
          </Row>

          <Row>
            <ReactTable
              headings={propertyHeading}
              data={propertyList || []}
              actionColumn={false}
              currentPage={currentPage}
              itemPerPage={pageLimit}
              totalItems={totalRecords}
              setPerPageLimit={setPageLimit}
              setCurrentPage={setCurrentPage}
              loading={propertyLoading || !propertyList}
              pagination={true}
            >
              <div className={styles.org}>
                <p>Properties</p>
              </div>
            </ReactTable>
          </Row>
          <Row>
            <ReactTable
              headings={serviceHeading}
              data={serviceList || []}
              actionColumn={false}
              currentPage={currentPageService}
              itemPerPage={pageLimitService}
              totalItems={totalRecordsService}
              setPerPageLimit={setPageLimitService}
              setCurrentPage={setCurrentPageService}
              loading={serviceLoading || !serviceList}
              pagination={true}
            >
              <div className={styles.org}>
                <p>Services</p>
              </div>
            </ReactTable>
          </Row>
          <Row>
            <ReactTable
              headings={employeeHeading}
              data={employeesList || []}
              actionColumn={false}
              currentPage={currentPageEmployee}
              itemPerPage={pageLimitEmployee}
              totalItems={totalRecordsEmployee}
              setPerPageLimit={setPageLimitEmployee}
              setCurrentPage={setCurrentPageEmployee}
              loading={employeeLoading || !employeesList}
              pagination={true}
            >
              <div className={styles.org}>
                <p>Employees</p>
              </div>
            </ReactTable>
          </Row>
          <Row>
            <ReactTable
              headings={roomHeading}
              data={roomList || []}
              actionColumn={false}
              currentPage={currentPageRoom}
              itemPerPage={pageLimitRoom}
              totalItems={totalRecordsRoom}
              setPerPageLimit={setPageLimitRoom}
              setCurrentPage={setCurrentPageRoom}
              loading={roomLoading || !roomList}
              pagination={true}
            >
              <div className={styles.org}>
                <p>Rooms</p>
              </div>
            </ReactTable>
          </Row>
        </Container>
      )}
    </DashboardLayout>
  );
};

export default QRCodeAndLinks;
