import { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { RxCross1 } from "react-icons/rx";
import { useNavigate, useParams } from "react-router-dom";
import {
  copyIconNew,
  crossIcon,
  deleteIcon,
  editIcon,
  eye,
  stripeIcon,
} from "../../../assests/Icons/icon";
import searchIcon from "../../../assests/svg/searchIcon.svg";
import {
  copyToClipBoard,
  getCurrentUserData,
  notifyBugsnagError,
  openQrCode,
  removeDashed,
  showToast,
} from "../../../common/utils/Functions";
import DashboardLayout from "../../../components/layouts/DashboardLayout";
import ConfirmationModal from "../../../components/modals/ConfirmationModal/ConfirmationModal";
import InactiveActiveModal from "../../../components/modals/InactiveActiveModal/InactiveActiveModal";
import PageHeading from "../../../components/pageHeading";
import AddEditPropertyModal from "../../../components/pagesComponents/manageProperty/AddPropertyModal";
import ReactTable from "../../../components/reactTable/ReactTable";
import InputField from "../../../components/theme/InputField";
import ThemeButton from "../../../components/theme/ThemeButton";
import useFetch from "../../../hooks/useFetch";
import { propertyType } from "../../../types/manageProperty";
import styles from "./manageProperties.module.scss";
import noimage from "../../../../src/assests/images/no-image.png";
import CommonButton from "../../../components/theme/button";
import { refreshList } from "../../../assests/Icons/dashboardIcons";
const ManageProperties = () => {
  const { uuid } = useParams();
  const userData = getCurrentUserData(`${uuid}`);
  const partner_name = userData?.organization_details?.partner_details?.name;

  /**
   * @State to store searched value data
   */
  const [searchValue, setSearchValue] = useState("");

  /**
   * @Pagination states
   * 1. pageLimit    :- store limit of data that will render on current page
   * 2. totalRecords :- store total number of data in property api
   * 3. currentPage  :- store current page number
   */
  const [pageLimit, setPageLimit] = useState<number>(10);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);

  /** states to store data of property */
  const [propertyList, setPropertyList] = useState<propertyType[]>();
  const [selectedItem, setSelectedItem] = useState<propertyType>();
  const [allState, setState] = useState<any>([]);
  const [showAddForm, setShowAddForm] = useState<boolean>(false); // state to handle Add Property Form
  const [showEditForm, setShowEditForm] = useState<boolean>(false); // state to handle Edit Property Form
  const [openInActive, setOpenInActive] = useState<boolean>(false); // state to handle In-active Property modal
  const [openDelete, setOpenDelete] = useState<boolean>(false); // state to handle Delete Property modal
  const [currentStep, setCurrentStep] = useState<number>(1); // state to handle Add/Edit form steps
  const navigate = useNavigate();

  /**
   * @Function to handle close logic of add/edit Property modal
   */
  const handleClose = () => {
    setShowAddForm(false);
    setShowEditForm(false);
    setCurrentStep(1);
  };

  /** ------- 1. @listApi ------ */
  const [
    propertyListApi,
    { response: listResponse, error: listError, loading: listLoading },
  ] = useFetch("/property/list", {
    method: "post",
  });

  /** -------2. @statusApi ------ */
  const [
    statusApi,
    { response: statusResponse, loading: statusLoading, error: statusError },
  ] = useFetch(`/property/active-inactive/${selectedItem?.uuid}`, {
    method: "post",
  });

  /** -------3. @deleteApi ------ */
  const [
    deleteApi,
    { response: deleteResponse, loading: deleteLoading, error: deleteError },
  ] = useFetch(`/property/delete/${selectedItem?.uuid}`, {
    method: "delete",
  });

  const [
    stateApi,
    { response: stateresponse, loading: stateLoading, error: stateError },
  ] = useFetch(`/auth/get-states`, {
    method: "get",
  });

  const [
    refreshApi,
    { response: refreshResponse, loading: refreshLoading, error: refreshError },
  ] = useFetch(`/organization/update-guesty-properties`, {
    method: "put",
  });
  /**
   * @Array :- for table headings that passed as a prop to React-Table component
   */
  const urlForAdmin = (item: propertyType) => {
    if (uuid) {
      return "/" + uuid + `/manage-properties/view-property/${item.uuid}`;
    } else {
      return `/manage-properties/view-property/${item.uuid}`;
    }
  };
  const headings =
    userData?.role_id === 2
      ? [
        {
          key: "id",
          label: "Property ID",
          onClick: (item: propertyType) => {
            navigate(urlForAdmin(item));
          },
        },
        {
          key: "name",
          label: "Property Name",
          onClick: (item: propertyType) => {
            navigate(urlForAdmin(item));
          },
        },
        {
          key: "address",
          label: "Property Address",
        },
        {
          key: "average_net_tip_amount",
          label: "Average Net Tip Amount",
          textAlign: "right",
        },
      ]
      : [
        {
          key: "id",
          label: "Property ID",
          onClick: (item: propertyType) => {
            navigate(urlForAdmin(item));
          },
        },
        {
          key: "name",
          label: "Property Name",
          onClick: (item: propertyType) => {
            navigate(urlForAdmin(item));
          },
        },
        {
          key: "average_net_tip_amount",
          label: "Average Net Tip Amount",
          textAlign: "right",
        },
      ];

  /**
   * @function :-  to handle In-active Property Modal on/open
   */
  const handleInActive = () => setOpenInActive(false);

  /**
   * @function :-  to handle delete Property Modal on/open
   */
  const handleDelete = () => setOpenDelete(false);

  /**
   * @Array :- for action button dropdown values and passes to React table component
   */
  const actionButtonOptions =
    userData?.role_id === 2
      ? [
        {
          name: "View Property Info",
          icon: eye,
          onClick: (item: propertyType) => {
            navigate(urlForAdmin(item));
          },
        },
        {
          name: "View Qr Code",
          icon: eye,
          onClick: (item: propertyType) => {
            openQrCode(item?.qr_code as string);
          },
        },
        {
          name: "Copy Tip URL",
          icon: copyIconNew,
          onClick: (item: propertyType) => {
            copyToClipBoard(item?.copy_link as string);
          },
        },
        {
          name: "Edit",
          icon: editIcon,
          onClick: (item: propertyType) => {
            navigate(
              uuid
                ? "/" + uuid + `/manage-properties/edit/${item?.uuid}`
                : `/manage-properties/edit/${item?.uuid}`
            );
          },
        },
        {
          name: "Inactive",
          icon: crossIcon,
          onClick: (item: propertyType) => {
            setOpenInActive(true);
            setSelectedItem(item);
          },
        },
        {
          name: "Delete",
          icon: deleteIcon,
          onClick: (item: propertyType) => {
            setSelectedItem(item);
            setOpenDelete(true);
          },
        },
      ]
      : [
        {
          name: "View Property Info",
          icon: eye,
          onClick: (item: propertyType) => {
            navigate(urlForAdmin(item));
          },
        },
        {
          name: "View Qr Code",
          icon: eye,
          onClick: (item: propertyType) => {
            openQrCode(item?.qr_code as string);
          },
        },
        {
          name: "Copy Tip URL",
          icon: copyIconNew,
          onClick: (item: propertyType) => {
            copyToClipBoard(item?.copy_link as string);
          },
        },
        {
          name: "Edit",
          icon: editIcon,
          onClick: (item: propertyType) => {
            navigate(
              uuid
                ? "/" + uuid + `/manage-properties/edit/${item?.uuid}`
                : `/manage-properties/edit/${item?.uuid}`
            );
          },
        },
      ];

  /**
   * @Call all property data using @listApi constant
   */
  useEffect(() => {
    const timer = setTimeout(() => {
      try {
        propertyListApi({
          start: (currentPage - 1) * pageLimit,
          limit: pageLimit,
          search: searchValue,
        });
      } catch (e: any) {
        notifyBugsnagError(e, {
          api: "propertyListApi",
        });
      }
    }, 500);
    return () => clearTimeout(timer);
  }, [searchValue, currentPage, pageLimit]);

  /**
   * @handle_StatusApi_Response on change active status of property api call using @statusApi constant
   * => in this effect
   * 1. @if_statement   that it responded ok then show toast success message
   * 2. @toggleActive   this handler use to close Ative/Inactive Modal
   * 3. @setServiceList update property object that matched with changed status item id
   * 4. @if_statement  to handle error if api not responded
   */
  useEffect(() => {
    if (statusResponse) {
      showToast(statusResponse.message as string, "success");
      handleInActive();
      const updatedList = propertyList?.map((item: propertyType) =>
        selectedItem?.id === item.id
          ? { ...item, is_active: item.is_active ? 0 : 1 }
          : item
      );
      setPropertyList(updatedList);
    }
    if (statusError) {
      showToast(statusError.message as string, "error");
    }
  }, [statusResponse, statusError]);

  /**
   * @handle_DeleteApi_Response on delete property api call using @deleteApi constant
   * => in this effect
   * 1. @if_statement   that it responded ok then show toast success message
   * 2. @toggleDelete   this handler use to close Delete Modal
   * 3. @setServiceList update property object that matched with deleted item id
   * 4. @if_statement   to handle error if api not responded
   */
  useEffect(() => {
    if (deleteResponse) {
      showToast(deleteResponse.message as string, "success");
      try {
        propertyListApi({
          start: (currentPage - 1) * pageLimit,
          limit: pageLimit,
          search: searchValue,
        });
      } catch (e: any) {
        notifyBugsnagError(e, {
          api: "propertyListApi",
        });
      }
      handleDelete();
    }
    if (deleteError) {
      showToast(deleteError.message as string, "error");
    }
  }, [deleteResponse, deleteError]);

  /**
   * @handle_Response of above api call using @listApi constant
   * => in this effect
   * 1. @setTotalRecords setState updates with total records
   * 2. @setPropertyList  setState updates with incoming property-data
   * 3. @if_statement    to handle error if api not responded
   */
  useEffect(() => {
    if (listResponse) {
      setTotalRecords(listResponse.data?.totalRecords);

      const updatedPropertyList = listResponse.data.list?.map(
        (item: propertyType) => ({
          ...item,
          id: (<div>{item?.id}{" "}{(item?.property_stripe_status === 1 || item?.property_stripe_status === 2) ? stripeIcon : ''}</div>),
          address: `${item.address ? item.address + ", " : ""} ${item.city ? item.city + ", " : ""
            } ${item.property_state_details?.name
              ? item.property_state_details?.name + ", "
              : ""
            } ${item.zip_code ? item.zip_code + ", " : ""} United States`,
          average_net_tip_amount: item?.average_net_tip_amount
            ? item?.average_net_tip_amount.toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
            })
            : removeDashed,
          name: (
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                gap: 10,
              }}
            >
              {" "}
              <div
                style={{
                  height: 30,
                  width: 30,
                  borderRadius: "50%",
                  overflow: "hidden",
                  backgroundColor: item?.theme_color
                    ? item.theme_color
                    : item?.property_organization_details?.theme_color,
                }}
              >
                {" "}
                <img
                  src={item?.property_media_details?.url ?? noimage}
                  alt=""
                  style={{
                    height: 30,
                    width: 30,
                    borderRadius: "50%",
                    objectFit: "cover",
                  }}
                />
              </div>{" "}
              {item?.name}
            </div>
          ),
        })
      );
      setPropertyList(updatedPropertyList);
    }
    if (listError) {
      showToast(listError.message as string, "error");
    }
  }, [listResponse, listError]);

  useEffect(() => {
    stateApi();
  }, []);
  useEffect(() => {
    const allNewstate: any = [];
    if (stateresponse) {
      stateresponse?.data?.map((item: any) => {
        allNewstate.push({ value: item?.code, label: item.name, id: item.id });
      });
      setState([
        {
          value: null,
          label: " Select State",
          id: null,
        },
        ...allNewstate,
      ]);
    }
  }, [stateresponse]);

  useEffect(() => {
    if (refreshResponse) {
      showToast(refreshResponse.message as string, "success");
      try {
        propertyListApi({
          start: (currentPage - 1) * pageLimit,
          limit: pageLimit,
          search: searchValue,
        });
      } catch (e: any) {
        notifyBugsnagError(e, {
          api: "propertyListApi",
        });
      }
    }
    if (refreshError) {
      showToast(refreshError.message as string, "error");
    }
  }, [refreshResponse, refreshError]);

  return (
    <DashboardLayout>
      <div className={styles.dash_page}>
        <Container>
          <Row className="g-xl-4 g-3">
            <Col xxl={12} xl={12}>
              <PageHeading
                heading="Manage Properties"
                subHeading="Here is the information about all your properties."
              >
                <div className={styles.search}>
                  <div className={styles.searchField}>
                    {/* <GoSearch className={styles.searchIcon} /> */}
                    <InputField
                      name="search_property_name"
                      icon={searchIcon}
                      placeholder="Search by Property Name"
                      value={searchValue}
                      onChange={(e) => {
                        setCurrentPage(1);
                        setSearchValue(e.target.value);
                      }}
                      autoComplete="off"
                    />
                    {searchValue ? (
                      <span
                        className={styles.crossIcon}
                        onClick={() => setSearchValue("")}
                      >
                        <RxCross1 />
                      </span>
                    ) : null}
                  </div>
                  {partner_name === "Guesty" ? (
                    <CommonButton
                      loading={refreshLoading}
                      title="  Refresh List"
                      icon={refreshList}
                      // disabled={refreshLoading}
                      onClick={() => {
                        refreshApi();
                      }}
                    />
                  ) : (
                    ""
                  )}

                  {userData?.role_id !== 3 && (
                    <ThemeButton
                      variant="primary"
                      onClick={() => {
                        setShowAddForm(true);
                      }}
                    >
                      Add New Property
                    </ThemeButton>
                  )}
                </div>
              </PageHeading>
            </Col>
            <Col xxl={12} xl={12}>
              <ReactTable
                data={propertyList || []}
                headings={headings}
                actionColumn
                statusColumn
                actionButtonOptions={actionButtonOptions}
                currentPage={currentPage}
                itemPerPage={pageLimit}
                totalItems={totalRecords}
                setPerPageLimit={setPageLimit}
                pageLimit={pageLimit}
                setCurrentPage={setCurrentPage}
                loading={listLoading || !propertyList}
                pagination={true}
              />
            </Col>
            <AddEditPropertyModal
              propertyListApi={propertyListApi}
              show={showAddForm}
              currentStep={currentStep}
              setCurrentPage={setCurrentPage}
              setCurrentStep={setCurrentStep}
              handleClose={handleClose}
              title={showEditForm ? "Edit Property " : "Add New Property "}
              editModal={showEditForm}
              allState={allState}
            />
            <InactiveActiveModal
              handleToggle={handleInActive}
              title={`Are you sure you want to ${selectedItem?.is_active ? "deactivate" : "activate"
                }
                    this property?`}
              show={openInActive}
              heading={selectedItem?.is_active ? "Deactivate" : "Activate"}
              loading={statusLoading}
              confirm={() =>
                statusApi({
                  is_active: selectedItem?.is_active ? 0 : 1,
                })
              }
            />
            <ConfirmationModal
              handleToggle={handleDelete}
              title="Are you sure you want to delete
              this property?"
              show={openDelete}
              loading={deleteLoading}
              confirm={() => deleteApi()}
            />
          </Row>
        </Container>
      </div>
    </DashboardLayout>
  );
};

export default ManageProperties;
