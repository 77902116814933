import moment from "moment";
import { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import {
  OrganizationIcon,
  TipAmountIcon,
  TipNumberIcon,
  TipRevenueIcon,
  TipperIcon,
  TransactionIcon,
} from "../../../assests/Icons/dashboardIcons";
import { stripeIcon, tipIcon } from "../../../assests/Icons/icon";
import { notifyBugsnagError, removeDashed, showToast } from "../../../common/utils/Functions";
import DashboardLayout from "../../../components/layouts/DashboardLayout";
import PageHeading from "../../../components/pageHeading";
import ReactTable from "../../../components/reactTable/ReactTable";
import ThemeButton from "../../../components/theme/ThemeButton";
import TipAmountModal from "../../../components/tipAmountModal/tipAmountModal";
import useFetch from "../../../hooks/useFetch";
import styles from "./dashboard.module.scss";

const Dashboard = () => {
  const navigate = useNavigate();
  const initialCountData = [
    {
      icon: TransactionIcon,
      title: "Tip Transactions Today",
      amount: 0,
      key: "todayTipTransaction",
      onclick: () => {
        navigate("/reporting", {
          state: {
            filterValue: { value: "today", label: "Today" },
          },
        });
      },
    },
    {
      icon: OrganizationIcon,
      title: "Organizations",
      amount: 0,
      key: "organizations",
      onclick: () => {
        navigate("/organizations");
      },
    },
    {
      icon: TipperIcon,
      title: "Tippers",
      amount: 0,
      key: "tippers",
      onclick: () => {
        navigate("/tippers");
      },
    },
    {
      icon: TipNumberIcon,
      title: "Total Number of Tips",
      amount: 0,
      key: "totalTips",
      onclick: () => {
        navigate("/reporting");
      },
    },
    {
      icon: TipAmountIcon,
      title: "Gross Tipped Amount",
      amount: 0,
      key: "totalTipperAmt",
      onclick: () => {
        navigate("/reporting");
      },
    },
    {
      icon: TipRevenueIcon,
      title: "Total TopTipper Revenue",
      amount: 0,
      key: "totalRevenue",
      onclick: () => {
        navigate("/reporting");
      },
    },
  ];
  const [countData, setCountData] = useState(initialCountData);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageLimit, setPageLimit] = useState<number>(10);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [dashboardDataList, setDashboardDataList] = useState<any>([]);
  const [showTip, setShowTip] = useState(false);
  const [dashboardTipItems, setDashboardTipItems] = useState<any>([]);
  const handleTipClose = () => setShowTip(false);

  /** Handle modal states */
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  /** Api definition */
  const [countApi, { response: countResponse, error: countError }] =
    useFetch("/dashboard/count");

  /** Api definition */
  const [
    getDashBoardData,
    {
      response: dashBoardDataResponse,
      error: dashBoardDataError,
      loading: dashBoardDataLoading,
    },
  ] = useFetch("/dashboard/all-tips", {
    method: "POST",
  });

  const headings = [
    {
      key: "org_name",
      label: "Organization Name",
    },
    {
      key: "prop_name",
      label: "Property Name",
    },
    {
      key: "net_tip_amount",
      label: "Net Tip Amount",
      textAlign: "right",
    },
    {
      key: "tipper_email",
      label: "Tipper Email",
      textAlign: "end",
    },
  ];

  /** Call countApi */
  useEffect(() => {
    try {
      getDashBoardData({
        start: (currentPage - 1) * pageLimit,
        limit: pageLimit,
        startDate: moment().format("MM/DD/YYYY"),
        endDate: moment().format("MM/DD/YYYY"),
        organization_uuid: null,
        sortedBy: null,
        search: "",
      });
    } catch (e: any) {
      notifyBugsnagError(e, {
        api: "getDashBoardData",
      });
    }
    countApi();
  }, [currentPage, pageLimit]);

  /** Handle countApi response */
  useEffect(() => {
    if (countResponse) {
      const responseData = countResponse.data;
      const updatedData = countData.map((item) => ({
        ...item,
        amount: Math.round(responseData[item.key]),
      }));
      setCountData(updatedData);
    }
    if (countError) {
      showToast(countError.message as string, "error");
    }
  }, [countResponse, countError]);

  useEffect(() => {
    if (dashBoardDataResponse) {
      setTotalRecords(dashBoardDataResponse?.data?.totalRecords);
      const createDashData = dashBoardDataResponse?.data?.list?.map(
        (item: any) => {
          return {
            org_name: item?.org_name,
            prop_name: item?.property_name,
            net_tip_amount: item?.net_tip_amount ? (
              <span
                style={{
                  fontSize: 14,
                  fontWeight: 400,
                }}
              >
                {item?.net_tip_amount?.toLocaleString("en-US", {
                  style: "currency",
                  currency: "USD",
                })}{" "}
                <span
                  onClick={() => {
                    setDashboardTipItems([
                      {
                        label: "Total Trans. Amount",
                        value: item?.total_amount
                          ? item?.total_amount.toLocaleString("en-US", {
                            style: "currency",
                            currency: "USD",
                          })
                          : removeDashed,
                      },
                      {
                        label: "Stripe Fee",
                        value: item?.stripe_fee
                          ? item?.stripe_fee.toLocaleString("en-US", {
                            style: "currency",
                            currency: "USD",
                          })
                          : removeDashed,
                      },
                      {
                        label: "TopTipper Fee",
                        value: item?.top_tipper_fees
                          ? item?.top_tipper_fees.toLocaleString("en-US", {
                            style: "currency",
                            currency: "USD",
                          })
                          : removeDashed,
                      },
                      {
                        label: "Net Tip Amount",
                        value: item?.net_tip_amount
                          ? item?.net_tip_amount.toLocaleString("en-US", {
                            style: "currency",
                            currency: "USD",
                          })
                          : removeDashed,
                      },
                      {
                        label: '',
                        value: <div className={styles.stripeId}>
                          {stripeIcon}{" "}<span>{" "}{item?.stripe_connect_account_id ?? removeDashed}</span>
                        </div>
                      }
                    ]);
                    setShowTip(true);
                  }}
                >
                  {tipIcon}
                </span>
              </span>
            ) : (
              removeDashed
            ),
            tipper_email: item?.tipper_details?.email,
          };
        }
      );
      setDashboardDataList([...createDashData]);
    }
    if (dashBoardDataError) {
      showToast(dashBoardDataError.message, "error");
    }
  }, [dashBoardDataError, dashBoardDataResponse]);
  return (
    <DashboardLayout>
      <div className={styles.dash_page}>
        <Container>
          <PageHeading
            heading="Dashboard"
            subHeading="Here is the information about all your organizations."
          />

          <div className={styles.cardsWrap}>
            {countData.map((item) => (
              <div
                className={styles.card_outer}
                key={item.key}
                onClick={item.onclick}
                style={{ cursor: "pointer" }}
              >
                <div
                  className={`${styles.card} ${item.key === "totalRevenue" ? styles.revenueCard : ""
                    }`}
                >
                  <div className={styles.icon}>{item.icon}</div>
                  <div className={styles.amount}>
                    {item.key === "totalTipperAmt" ||
                      item.key === "totalRevenue"
                      ? `$${item.amount?.toLocaleString("en-US", {
                        // style: "currency",
                        // currency: "USD",
                      })}`
                      : item.amount?.toLocaleString("en-US")}
                  </div>
                  <div className={styles.title}>{item.title}</div>
                </div>
              </div>
            ))}
          </div>

          <ReactTable
            data={dashboardDataList || []}
            headings={headings}
            actionColumn={false}
            statusColumn={false}
            currentPage={currentPage}
            itemPerPage={pageLimit}
            totalItems={totalRecords}
            setPerPageLimit={setPageLimit}
            pageLimit={pageLimit}
            setCurrentPage={setCurrentPage}
            loading={dashBoardDataLoading || !dashboardDataList}
            pagination={true}
          >
            <TipAmountModal
              show={showTip}
              handleClose={handleTipClose}
              title="Tip Amount Information"
              tipItems={dashboardTipItems}
            >
              <ThemeButton onClick={() => handleTipClose()}>Close</ThemeButton>
            </TipAmountModal>
            <div className="d-flex justify-content-between p-2">
              <h3
                style={{
                  fontWeight: 600,
                  fontSize: 20,
                }}
              >
                Today's Tips
              </h3>
              <Link
                to="/dashboard/all-tips"
                style={{
                  textDecoration: "none",
                }}
              >
                {" "}
                <ThemeButton>View More</ThemeButton>
              </Link>
            </div>
          </ReactTable>
        </Container>
      </div>
    </DashboardLayout>
  );
};
export default Dashboard;
