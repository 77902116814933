import { Col, Container, InputGroup, Row } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import DashboardLayout from "../../../components/layouts/DashboardLayout";
import PageHeading from "../../../components/pageHeading";
import "./setting.scss";
import { useEffect, useState } from "react";
import useFetch from "../../../hooks/useFetch";
import { toast } from "react-toastify";
import { notifyBugsnagError, showToast } from "../../../common/utils/Functions";
import ReactLoader from "../../../components/reactLoader/ReactLoader";

const Settings = () => {
  const [isChecked, setIsChecked] = useState(false);

  const [
    settingApi,
    { response: settingResponse, loading: settingLoading, error: settingError },
  ] = useFetch("admin/change-tipping-settings", {
    method: "POST",
  });

  const [
    getsettingApi,
    {
      response: getSettingResponse,
      loading: getSettingLoading,
      error: getSettingError,
    },
  ] = useFetch("admin/get-tipping-settings");

  /** @function to handle the onChange event of setting */
  const handleSettingChange = (e: any) => {
    const isEnable = e.target.checked;
    setIsChecked(isEnable);
    try {
      settingApi({
        is_tipping_enabled: isEnable ? 1 : 0,
      });
    } catch (e: any) {
      notifyBugsnagError(settingError, {
        api: "settingApi",
      });
    }
  };

  useEffect(() => {
    if (settingResponse) {
      showToast(settingResponse.message, "success");
    }
    if (settingError) {
      showToast(settingError.message, "error");
    }
  }, [settingResponse, settingError]);

  useEffect(() => {
    getsettingApi();
  }, []);
  useEffect(() => {
    if (getSettingResponse) {
      setIsChecked(getSettingResponse?.data?.is_tipping_enabled);
    }
    if (getSettingError) {
      showToast(getSettingError.message, "error");
    }
  }, [getSettingResponse, getSettingError]);
  return (
    <DashboardLayout>
      {getSettingLoading ? (
        <ReactLoader viewHeight={"calc(100vh - 200px)"} />
      ) : (
        <div className={"dash_page"}>
          <Container>
            <PageHeading
              heading="Settings"
              subHeading="Here you can change your account settings."
            />
            <Row>
              <Col xs={12} md={6} lg={5}>
                <div className={"setting_card"}>
                  <div>
                    <h6>Disable Tipping</h6>
                    <p>
                      Here you can Enable/Disable Tipping for All Organizations.
                    </p>
                  </div>
                  <div className={"toggleButton"}>
                    <InputGroup className="inputGroup">
                      <Form.Check // prettier-ignore
                        type="switch"
                        id="custom-switch"
                        className={"form_check"}
                        disabled={settingLoading}
                        checked={isChecked}
                        onChange={(e) => {
                          handleSettingChange(e);
                        }}
                      />
                      {/* <span
                        style={{
                          left: isChecked ? "10%" : "",
                          right: isChecked ? "" : "10%",
                        }}
                      >
                        {isChecked ? "ON" : "OFF"}
                      </span> */}
                    </InputGroup>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      )}
    </DashboardLayout>
  );
};

export default Settings;
