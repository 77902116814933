import moment from 'moment';
import { useEffect, useState } from 'react';
import { Container, Dropdown } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import { OrganizationIcon, TipAmountIcon, TipNumberIcon, TipRevenueIcon, TipperIcon, TransactionIcon } from '../../../../assests/Icons/dashboardIcons';
import { notifyBugsnagError, removeDashed, showToast } from '../../../../common/utils/Functions';
import DashboardLayout from '../../../../components/layouts/DashboardLayout';
import PageHeading from '../../../../components/pageHeading';
import RangeDateModal from '../../../../components/rangeDateModal/rangeDateModal';
import ReactExcel from '../../../../components/reactExcel/ReactExcel';
import ReactTable from '../../../../components/reactTable/ReactTable';
import ReactSelectField from '../../../../components/theme/ReactSelectField';
import useFetch from '../../../../hooks/useFetch';
import { OrganizationItem } from '../../../../types/organization';
import styles from './conversionTracking.module.scss';
import { ConversionTrackItem } from '../../../../types/conversionTrackingType';

const ConversionTracking = () => {
    const navigate = useNavigate()
    const location = useLocation();
    const initialCountData = [
        {
            icon: TransactionIcon,
            title: "Impressions",
            amount: 0,
            key: "impressions",
        },
        {
            icon: OrganizationIcon,
            title: "Clicks",
            amount: 0,
            key: "clicks",
        },
        {
            icon: TipperIcon,
            title: "Tip Attempts",
            amount: 0,
            key: "tip_attempts",
        },
        {
            icon: TipNumberIcon,
            title: "Tip Success",
            amount: 0,
            key: "tip_success",
        },
        {
            icon: TipAmountIcon,
            title: "Review Clicks",
            amount: 0,
            key: "review_clicks",
        },
        {
            icon: TipRevenueIcon,
            title: "Conversion Rate",
            amount: 0,
            key: "conversion_rate",
        },
    ];
    const [conversionTrackList, setConversionTrackList] = useState<ConversionTrackItem[]>();
    /**
 * @Pagination states
 * 1. pageLimit    :- store limit of data that will render on current page
 * 2. totalRecords :- store total number of data in service api
 * 3. currentPage  :- store current page number
 */
    const [pageLimit, setPageLimit] = useState<number>(10);
    const [totalRecords, setTotalRecords] = useState<number>(0);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [countData, setCountData] = useState(initialCountData);
    const [filterValues, setFilterValues] = useState<any>([]);
    const [excelData, setExcelData] = useState<any>([]);
    const [organizationUuid, setOrganizationUuid] = useState(null);
    const [startDate, setStartDate] = useState<string | null>(null);
    const [endDate, setEndDate] = useState<string | null>(null);
    const [showRange, setShowRange] = useState(false);
    const [selectedFilter, setSelectedFilter] = useState<{
        value: string;
        label: string;
    } | null>(null);
    const [sourceFilter, setSourceFilter] = useState<{
        value: string;
        label: string;
    } | null>(null);

    const handleCloseRange = () => setShowRange(false);
    const handleShowRange = () => setShowRange(true);

    const [orgDataApi, { response: orgDataResponse, error: orgDataError }] =
        useFetch("/organization/list", {
            method: "post",
        });


    /** Api to show tracking data */
    const [conversionTrackingCountApi, { response: countResponse, error: countError, loading: countLoading }] =
        useFetch("/common/user-session/conversion-tracking", {
            method: 'post',
        });

    const [
        getExcelData,
        {
            response: excelDataResponse,
            error: excelDataError,
            loading: excelDataLoading,
        },
    ] = useFetch("/common/user-session/export-conversion-tracking", {
        method: 'post',
    });

    useEffect(() => {
        if (excelDataResponse) {
            if (!excelDataResponse?.data || excelDataResponse?.data?.length === 0) {
                showToast('No Data Found', 'error')
                return;
            }
            const updatedOrgExcelData = excelDataResponse?.data?.map((item: any) => ({
                sessionid: item?.session_id ? `${item?.session_id}` : removeDashed,
                initial_tipping_screen: item?.initial_tipping_screen ? `${item?.initial_tipping_screen}` : removeDashed,
                copy_qr: item?.copy_qr ? `${item?.copy_qr}` : removeDashed,
                scan_qr: item?.scan_qr ? `${item?.scan_qr}` : removeDashed,
                note: item?.is_note_visible ? `${item?.is_note_visible}` : removeDashed,
                initial_tipping_screen_time:
                    item.initial_tipping_screen_time ? `${moment(item.initial_tipping_screen_time).format("MM/DD/YYYY  hh:mm:ss A")}`
                        : removeDashed,
                tip_status: item?.tip_status ? `${item?.tip_status}` : removeDashed,
                tip_amount: item?.tip_amount ? `${item?.tip_amount}` : removeDashed,
                device_type: item?.device_type
                    ? `${item.device_type.charAt(0).toUpperCase()}${item.device_type.slice(1).toLowerCase()}`
                    : removeDashed,
                device_info: item?.device_info ? JSON.stringify(item.device_info) : removeDashed,
                organization_name: item?.organization?.name ? `${item?.organization?.name}` : removeDashed,
                property_name: item?.property?.name ? `${item?.property?.name}` : removeDashed,
                service_name: item?.property_services?.services_details?.name ? `${item?.property_services?.services_details?.name}` : removeDashed,
                room_name: item?.property_rooms_details?.name ? `${item?.property_rooms_details?.name}` : removeDashed,
                employee_name: item?.property_Employees?.name ? `${item?.property_Employees?.name}` : removeDashed,
                click_continue: item?.click_continue ? `${item?.click_continue}` : removeDashed,
                click_continue_time: item?.click_continue_time
                    ? `${moment(item.click_continue_time).format("MM/DD/YYYY  hh:mm:ss A")}`
                    : removeDashed,
                click_send_tip: item?.click_send_tip ? `${item?.click_send_tip}` : removeDashed,
                click_send_tip_time: item?.click_send_tip_time
                    ? `${moment(item.click_send_tip_time).format("MM/DD/YYYY  hh:mm:ss A")}`
                    : removeDashed,
                click_google_review: item?.click_google_review ? `${item?.click_google_review}` : removeDashed,
                click_facebook_review: item?.click_facebook_review ? `${item?.click_facebook_review}` : removeDashed,
                click_trip_advisor_review: item?.click_trip_advisor_review ? `${item?.click_trip_advisor_review}` : removeDashed,
                click_yelp_review: item?.click_yelp_review ? `${item?.click_yelp_review}` : removeDashed,
                error_reason: item?.error_reason ? `${item?.error_reason}` : removeDashed,
            }));
            setExcelData((updatedOrgExcelData));
        }
        if (excelDataError) {
            showToast(excelDataError.message as string, "error");
        }
    }, [excelDataResponse, excelDataError]);

    const handleExcelApi = () => {
        try {
            getExcelData({
                startDate,
                endDate,
                organization_uuid: organizationUuid,
                url_type: sourceFilter?.value
            });
        } catch (e: any) {
            notifyBugsnagError(excelDataError, {
                api: "getExcelData",
            });
        }
    };

    const excelHeaders =
        [
            { key: "sessionid", header: "Session Id", width: 40 },
            { key: "initial_tipping_screen", header: "Impressions", width: 40 },
            { key: "copy_qr", header: "Copy Link", width: 40 },
            { key: "scan_qr", header: "Scan QR Code", width: 40 },
            { key: "note", header: "Note", width: 40 },
            { key: "initial_tipping_screen_time", header: "Impression Date And Time", width: 40 },
            { key: "tip_status", header: "Status", width: 40 },
            { key: "tip_amount", header: "Amount", width: 40 },
            { key: "device_type", header: "Device Type", width: 40 },
            { key: "device_info", header: "Device Info", width: 200 },
            { key: "organization_name", header: "Organization Name", width: 40 },
            { key: "property_name", header: "Property Name", width: 40 },
            { key: "service_name", header: "Service Name", width: 40 },
            { key: "room_name", header: "Room Name", width: 40 },
            { key: "employee_name", header: "Employee Name", width: 40 },
            { key: "click_continue", header: "Click Continue", width: 40 },
            { key: "click_continue_time", header: "Click Continue Date And Time", width: 40 },
            { key: "click_send_tip", header: "Click Send Tip", width: 40 },
            { key: "click_send_tip_time", header: "Click Send Tip Date And Time", width: 40 },
            { key: "click_google_review", header: "Google Review", width: 40 },
            { key: "click_facebook_review", header: "Facebook Review", width: 40 },
            { key: "click_trip_advisor_review", header: "Trip Advisor Review", width: 40 },
            { key: "click_yelp_review", header: "Yelp Review", width: 40 },
            { key: "error_reason", header: "Error Reason", width: 140 },
        ]

    useEffect(() => {
        const timer = setTimeout(() => {
            try {
                conversionTrackingCountApi({
                    startDate,
                    endDate,
                    organization_uuid: organizationUuid,
                    url_type: sourceFilter?.value
                })
            } catch (e: any) {
                notifyBugsnagError(e, { api: "conversionTrackingCountApi" });
            }
        }, 500);
        return () => clearTimeout(timer);
    }, [startDate,
        endDate, organizationUuid, sourceFilter?.value])

    /** Handle countApi response */
    useEffect(() => {
        if (countResponse) {
            const responseData = countResponse.data?.conversionSession;
            const updatedData = countData.map((item) => ({
                ...item,
                amount: responseData[item.key],
            }));
            setCountData(updatedData);
        }
        if (countError) {
            showToast(countError.message as string, "error");
        }
    }, [countResponse, countError]);

    useEffect(() => {
        const timer = setTimeout(() => {
            try {
                orgDataApi({
                    start: 0,
                    limit: -1,
                    search: "",
                    stripe_status: null,
                    is_active: 1,
                });
            } catch (e: any) {
                notifyBugsnagError(e, {
                    api: "orgDataApi",
                });
            }
        }, 500);
        return () => clearTimeout(timer);
    }, []);

    useEffect(() => {
        let updateOrg;
        if (orgDataResponse) {
            updateOrg = orgDataResponse?.data?.list?.map((item: OrganizationItem) => {
                return {
                    value: item?.uuid,
                    label: item?.name,
                };
            });
        }
        if (updateOrg) {
            setFilterValues([{ label: "All", value: null }, ...updateOrg]);
        }
    }, [
        orgDataResponse,
        orgDataError,
    ])

    useEffect(() => {
        if (location.state?.hasOwnProperty("filterValue")) {
            setSelectedFilter(location.state.filterValue);
            handleDateFilter(location.state.filterValue.value);
        }
    }, [location]);

    useEffect(() => {
        if (countResponse) {
            setTotalRecords(countResponse.data.totalRecords);
            const updateList = countResponse.data.conversionSessionList?.map((item: ConversionTrackItem) => {
                return {
                    ...item,
                    tip: item?.tip == null ? '0' : item?.tip,
                    conversion_rate: item.conversion_rate == null ? '0%' : `${((item.conversion_rate) * 100).toFixed()}%`
                };
            });
            setConversionTrackList([...updateList]);
        }
        if (countError) {
            showToast(countResponse?.message as string, "error");
        }
    }, [countResponse, countError]);

    const filters = [
        { value: "all", label: "All" },
        { value: "today", label: "Today" },
        { value: "week", label: "This Week" },
        { value: "month", label: "This Month" },
        { value: "Custom", label: "Custom" },
    ];

    const sourceFilterValues = [
        { value: null, label: "All" },
        { value: "copy_qr", label: "URL" },
        { value: "scan_qr", label: "QR Code" },
    ];

    /**
* @Array :- for table headings that passed as a prop to React-Table component
*/
    const headings =
        [
            { label: "Organization Name", key: "organization_name" },
            { label: "Impressions", key: "impressions" },
            { label: "Tips", key: "tip" },
            { label: "Conversion Rate", key: "conversion_rate" },
        ];

    const handleDateFilter = (value: string) => {
        const today = moment().format("MM/DD/YYYY");
        let startDate = null;
        let endDate = null;
        switch (value) {
            case "week":
                startDate = moment().startOf("week").format("MM/DD/YYYY");
                break;
            case "month":
                startDate = moment().startOf("month").format("MM/DD/YYYY");
                break;
            case "today":
                startDate = today;
                break;
            case "all":
                break;
            default:
                break;
        }
        if (value !== "all" && startDate) {
            endDate = today;
        }
        setStartDate(startDate);
        setEndDate(endDate);
    };

    const DropdownItems = () => {
        return (
            <div
                style={{
                    border: "1px solid #A9B0BF",
                    paddingLeft: "11px",
                    width: "34px",
                    borderRadius: "4px",
                    backgroundColor: "#ededed",
                }}
            >
                {" "}
                <Dropdown
                    align="end"
                    className={styles.dropDots}
                >
                    <Dropdown.Toggle variant="light" id="dropdown-basic" style={{ backgroundColor: 'transparent', border: 'none', transform: 'rotate(90deg)', color: '#676d7a', fontSize: '20px' }}>
                        ...
                    </Dropdown.Toggle>

                    <Dropdown.Menu
                        style={{
                            maxWidth: "max-content",
                            minWidth: "150px",
                            zIndex: "99",
                        }}
                    >
                        <Dropdown.Item
                            onClick={() => {
                                navigate('/reporting')
                            }}
                        >
                            {" "}
                            Tip Transactions
                        </Dropdown.Item>

                    </Dropdown.Menu>
                </Dropdown>
            </div>
        );
    };

    return (
        <DashboardLayout>
            <div className={styles.dash_page}>
                <Container>
                    <PageHeading
                        heading={

                            <span style={{ display: 'flex', gap: '10px' }}>
                                Conversion Tracking
                                <DropdownItems />
                            </span>
                        }
                        subHeading="Here is the information about all your reports."
                    />
                    <div className={styles.tipsWrap}>
                        <ReactSelectField
                            name="Sort By"
                            options={filters}
                            label="Filter By"
                            value={selectedFilter}
                            onChange={(e: any) => {
                                if (e.value === "Custom") {
                                    handleShowRange();
                                }
                                handleDateFilter(e.value);
                                setSelectedFilter(e);
                            }}
                        />

                        <ReactSelectField
                            name="organizations"
                            options={filterValues}
                            label="Organizations"
                            onChange={(e: any) => {
                                setOrganizationUuid(e.value);
                            }}
                        />
                        <ReactSelectField
                            name="source"
                            options={sourceFilterValues}
                            label="Source"
                            value={sourceFilter}
                            onChange={(e: any) => {
                                setSourceFilter(e);
                            }}
                        />

                        <ReactExcel
                            setExcelData={setExcelData}
                            loading={excelDataLoading}
                            onClick={handleExcelApi}
                            buttonTitle="Export CSV"
                            fileType="xlsx"
                            fileName="Session Tracking List"
                            coulmns={excelHeaders}
                            excelData={excelData}
                        />
                    </div>
                    <div className={styles.cardsWrap}>
                        {countData.map((item) => (
                            <div
                                className={styles.card_outer}
                                key={item.key}
                                style={{ cursor: "pointer" }}
                            >
                                <div
                                    className={`${styles.card} ${item.key === "conversion_rate" ? styles.revenueCard : ""
                                        }`}
                                >
                                    <div className={styles.icon}>{item.icon}</div>
                                    <div className={styles.amount}>
                                        {item.key === "conversion_rate"
                                            ? `${item.amount ? ((item.amount) * 100).toFixed() : removeDashed}%`
                                            : item.amount ? item.amount : removeDashed}
                                    </div>
                                    <div className={styles.title}>{item.title}</div>
                                </div>
                            </div>
                        ))}
                    </div>
                    <ReactTable
                        data={conversionTrackList || []}
                        headings={headings}
                        currentPage={currentPage}
                        itemPerPage={pageLimit}
                        totalItems={totalRecords}
                        setPerPageLimit={setPageLimit}
                        pageLimit={pageLimit}
                        setCurrentPage={setCurrentPage}
                        pagination={false}
                        statusColumn={false}
                        loading={countLoading || !conversionTrackList}
                        actionColumn={false}
                        enableSorting={true}
                    />
                    {selectedFilter?.value === "Custom" && (
                        <RangeDateModal
                            show={showRange}
                            handleClose={handleCloseRange}
                            setEndDate={setEndDate}
                            setStartDate={setStartDate}
                        />
                    )}
                </Container>
            </div>
        </DashboardLayout>
    )
}

export default ConversionTracking