/* eslint-disable @typescript-eslint/no-unused-vars */
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { Col, Container, Dropdown, Row } from "react-bootstrap";
import { IoEyeOutline } from "react-icons/io5";
import { RxCross1 } from "react-icons/rx";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  TipNumberIcon,
  TransactionIcon,
} from "../../../../assests/Icons/dashboardIcons";
import {
  copy,
  crossIcon,
  deleteIcon,
  edit,
  editIcon,
  eyes,
  link,
  linkStripe,
  tipIcon,
  unlink,
} from "../../../../assests/Icons/icon";
import searchIcon from "../../../../assests/svg/searchIcon.svg";
import {
  copyToClipBoard,
  getCurrentUserData,
  notifyBugsnagError,
  openQrCode,
  removeDashed,
  showToast,
} from "../../../../common/utils/Functions";
import DashboardLayout from "../../../../components/layouts/DashboardLayout";
import ConfirmationModal from "../../../../components/modals/ConfirmationModal/ConfirmationModal";
import InactiveActiveModal from "../../../../components/modals/InactiveActiveModal/InactiveActiveModal";
import PageHeading from "../../../../components/pageHeading";
import AddAccountModal from "../../../../components/pagesComponents/managerAccounts/addAccountModal/AddAccountModal";
import ReactLoader from "../../../../components/reactLoader/ReactLoader";
import ReactTable from "../../../../components/reactTable/ReactTable";
import InputField from "../../../../components/theme/InputField";
import { Property } from "../../../../components/theme/MultiSelectField";
import ThemeButton from "../../../../components/theme/ThemeButton";
import CommonButton from "../../../../components/theme/button";
import TipAmountModal from "../../../../components/tipAmountModal/tipAmountModal";
import useFetch from "../../../../hooks/useFetch";
import {
  managerAccountType,
  userProprtyType,
} from "../../../../types/managerAccountType";
import { viewPropertyType } from "../../../../types/viewPropertyType";
import { managerAccounts } from "../../../../validations/managerAccountSchema";
import styles from "../property/view-property.module.scss";
import PropertyServices from "./propertyservices";

interface contactAddress {
  address: string;
  city: string;
  property_state_details?: {
    name: string;
  };
  zip_code: string;
}

const initialValues: managerAccountType = {
  first_name: "",
  email: "",
  phone_number: "",
  assign_property_uuids: [""],
};

const ManageViewProperty = () => {
  /** get uuid from params */
  const { id, uuid } = useParams();
  const isOrg = window.location.href.includes("organizations");
  const [viewPropertyData, setViewPropertyData] =
    useState<viewPropertyType[]>();
  const data = getCurrentUserData(`${uuid}`);
  const [managerAccountList, setManagerAccountList] =
    useState<managerAccountType[]>();
  const [searchValue, setSearchValue] = useState("");

  /**
   * @Pagination states
   * 1. pageLimit    :- store limit of data that will render on current page
   * 2. totalRecords :- store total number of data in viewProperty api
   * 3. currentPage  :- store current page number
   */
  const [pageLimit, setPageLimit] = useState<number>(10);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);

  /**
   * @functions  to handle above ⬆️  @action states
   * 1. toggleAddEdit  :- Used to handle "Add/Edit" modal
   * 2. toggleDelete   :- Used to handle "Delete" modal
   * 3. toggleActive   :- Used to handle "In-active/Active"
   */
  const toggleAddEdit = () => setShow(!show);
  const toggleDelete = () => setShowDelete(!showDelete);
  const toggleActive = () => setShowActive(!showActive);

  /**
   * @Action states to open @Active @Delete @Add_Edit Modals
   */
  const [showDelete, setShowDelete] = useState(false); //  It's use to open "Delete" modal
  const [show, setShow] = useState(false); //  It's use to open "Add/Edit" modal
  const [editModal, setEditModal] = useState(false); //  It's use to change edit-form condition
  const [showActive, setShowActive] = useState(false); //  It's use to open "In-active/Active" modal
  /** states to store updated value and removed value  */
  const [updatedPropertyItems, setUpdatedPropertyItems] = React.useState<
    Property[]
  >([]);
  const [removeItems, setRemoveItems] = React.useState<Property[]>([]);
  const [selectedItem, setSelectedItem] = useState<managerAccountType>();
  const [showTip, setShowTip] = useState(false);
  const [openInActive, setOpenInActive] = useState<boolean>(false);
  const [openDelete, setOpenDelete] = useState<boolean>(false);
  const handleTipClose = () => setShowTip(false);
  const handleShow = () => setShow(true);
  const [dashboardTipItems, setDashboardTipItems] = useState<any>([]);
  const [openUnlinkModal, setOpenUnlinkModal] = useState(false);

  const handleUnlink = () => setOpenUnlinkModal(false);

  const handleInActive = () => setOpenInActive(false);
  const handleDelete = () => setOpenDelete(false);
  const navigate = useNavigate();
  /** -------- 1. @viewPropertyApi ------- */
  const [
    viewPropertyApi,
    {
      response: viewPropertyResponse,
      loading: viewPropertyLoading,
      error: viewPropertyError,
    },
  ] = useFetch(`/property/view/${id}`);

  /** -------- 2. @listManagerAccountApi ------- */
  const [
    listManagerAccountApi,
    {
      response: listManagerAccountResponse,
      loading: listManagerAccountLoading,
      error: listManagerAccountError,
    },
  ] = useFetch("/manager/list", {
    method: "POST",
  });

  /**----------3. @addManagerAccountApi ------- */
  const [
    addManagerAccountApi,
    {
      response: addManagerAccountResponse,
      loading: addManagerAccountLoading,
      error: addManagerAccountError,
    },
  ] = useFetch("/manager/add", {
    method: "POST",
  });

  /** --------4. @editManagerAccountApi ------ */
  const [
    editManagerAccountApi,
    {
      response: editManagerAccountResponse,
      loading: editManagerAccountLoading,
      error: editManagerAccountError,
    },
  ] = useFetch(`/manager/update/${selectedItem?.uuid}`, {
    method: "put",
  });

  /** --------5. @deleteManagerAccountApi ------ */
  const [
    updateManagerAccountApi,
    {
      response: updateManagerAccountResponse,
      loading: updateManagerAccountLoading,
      error: updateManagerAccountError,
    },
  ] = useFetch(`/manager/update/${selectedItem?.uuid}`, {
    method: "put",
    data: {
      remove_property_uuids: [viewPropertyResponse?.data?.uuid],
    },
    // Your other properties if any
  });

  /** -------6. @statusManagerAccountApi ------ */
  const [
    statusManagerAccountApi,
    {
      response: statusManagerAccountResponse,
      loading: statusManagerAccountLoading,
      error: statusManagerAccountError,
    },
  ] = useFetch(`/manager/active-inactive/${selectedItem?.uuid}`, {
    method: "post",
  });

  /** -------7. @statusApi ------ */
  const [
    statusApi,
    { response: statusResponse, loading: statusLoading, error: statusError },
  ] = useFetch(`/property/active-inactive/${id}`, {
    method: "post",
  });

  /** -------8. @deleteApi ------ */
  const [
    deleteApi,
    { response: deleteResponse, loading: deleteLoading, error: deleteError },
  ] = useFetch(`/property/delete/${id}`, {
    method: "delete",
  });

  /** -------8. @linkStripeApi ------ */
  const [
    propertyLinkStripeApi,
    { response: linkResponse, loading: linkLoading, error: linkError },
  ] = useFetch(`/property/stripe-integration/${id}`);

  /** -------9. @unlinkStripeApi ------ */
  const [
    propertyUnLinkStripeApi,
    { response: unlinkResponse, loading: unlinkLoading, error: unlinkError },
  ] = useFetch(`/property/unlink-stripe-account/${id}`);

  const linkUnlinkStripe = viewPropertyResponse?.data?.property_stripe_connect_account_id


  /**
   * @Call all manager account data  using @listManagerAccountApi constant
   */
  useEffect(() => {
    const timer = setTimeout(() => {
      try {
        listManagerAccountApi({
          start: (currentPage - 1) * pageLimit,
          limit: pageLimit,
          search: searchValue,
          property_uuid: id,
        });
      } catch (e: any) {
        notifyBugsnagError(e, {
          api: "listManagerAccountApi",
        });
      }
    }, 500);
    return () => clearTimeout(timer);
  }, [currentPage, pageLimit, searchValue]);

  /**
   * @handle_Response of above api call using @listManagerAccountApi constant
   * => in this effect
   * 1. @setTotalRecords setState updates with total records
   * 2. @setServiceList  setState updates with incoming manager-account-data
   * 3. @if_statement    to handle error if api not responded
   */
  useEffect(() => {
    if (listManagerAccountResponse) {
      setTotalRecords(listManagerAccountResponse.data?.totalRecords);
      const updatedList = listManagerAccountResponse.data.list.map(
        (item: any) => ({
          first_name: item.first_name,
          email: item.email,
          phone_code: item?.phone_code_details?.phone_code,
          phone_number: item?.phone_number,
          is_active: item?.is_active,
          show_phone: (
            <span>
              {item?.phone_number && item?.phone_code_details?.phone_code}{" "}
              {item?.phone_number ? item?.phone_number : removeDashed}
            </span>
          ),
          uuid: item?.uuid,
          assign_property_uuids: item?.user_properties?.map(
            (item: userProprtyType) => {
              return {
                label: item?.property_detail?.name,
                value: item?.property_detail?.uuid,
              };
            }
          ),
        })
      );
      setManagerAccountList(updatedList);
    }
    if (listManagerAccountError) {
      showToast(listManagerAccountError.message as string, "error");
    }
  }, [listManagerAccountResponse, listManagerAccountError]);

  /**
   * @handle_AddApi_Response of form-submition api call using @addManagerAccountApi constant
   * => in this effect
   * 1. @if_statement  that it responded ok then show toast success message
   * 2. @toggleAddEdit this handler use to close Add/Edit Modal
   * 3. @if_statement  to handle error if api not responded
   */
  useEffect(() => {
    if (addManagerAccountResponse) {
      showToast(addManagerAccountResponse.message as string, "success");
      try {
        listManagerAccountApi({
          start: (currentPage - 1) * pageLimit,
          limit: pageLimit,
          property_uuid: id,
        });
      } catch (e: any) {
        notifyBugsnagError(e, {
          api: "listManagerAccountApi",
        });
      }
      setCurrentPage(1);
      toggleAddEdit();
      formik.resetForm();
    }
    if (addManagerAccountError) {
      showToast(addManagerAccountError.message as string, "error");
    }
  }, [addManagerAccountResponse, addManagerAccountError]);

  /**
   * @handle_editApi_Response of form-submition api call using @editManagerAccountApi constant
   * => in this effect
   * 1. @if_statement  that it responded ok then show toast success message
   * 2. @toggleAddEdit this handler use to close Add/Edit Modal
   * 3. @if_statement  to handle error if api not responded
   */
  useEffect(() => {
    if (editManagerAccountResponse) {
      showToast(editManagerAccountResponse.message as string, "success");
      try {
        listManagerAccountApi({
          start: (currentPage - 1) * pageLimit,
          limit: pageLimit,
          property_uuid: id,
        });
      } catch (e: any) {
        notifyBugsnagError(e, {
          api: "listManagerAccountApi",
        });
      }
      setCurrentPage(1);
      toggleAddEdit();
      formik.resetForm();
    }
    if (editManagerAccountError) {
      showToast(editManagerAccountError.message as string, "error");
    }
  }, [editManagerAccountResponse, editManagerAccountError]);

  /**
   * @handle_DeleteManagerAccountApi_Response on delete manager account api call using @deleteManagerAccountApi constant
   * => in this effect
   * 1. @if_statement   that it responded ok then show toast success message
   * 2. @toggleDelete   this handler use to close Delete Modal
   * 3. @if_statement   to handle error if api not responded
   */
  useEffect(() => {
    if (updateManagerAccountResponse) {
      showToast(updateManagerAccountResponse.message as string, "success");
      try {
        listManagerAccountApi({
          start: (currentPage - 1) * pageLimit,
          limit: pageLimit,
          search: searchValue,
          property_uuid: id,
          // remove_property_uuids: [viewPropertyResponse?.data?.uuid],
        });
      } catch (e: any) {
        notifyBugsnagError(e, {
          api: "listManagerAccountApi",
        });
      }
      toggleDelete();
    }
    if (updateManagerAccountError) {
      showToast(updateManagerAccountError.message as string, "error");
    }
  }, [updateManagerAccountResponse, updateManagerAccountError]);

  /**
   * @handle_StatusManagerApi_Response on change active status of manager account api call using @statusManagerAccountApi constant
   * => in this effect
   * 1. @if_statement   that it responded ok then show toast success message
   * 2. @toggleActive   this handler use to close Ative/Inactive Modal
   * 3. @if_statement  to handle error if api not responded
   */
  useEffect(() => {
    if (statusManagerAccountResponse) {
      showToast(statusManagerAccountResponse.message as string, "success");
      toggleActive();
      const updatedList = managerAccountList?.map((item: managerAccountType) =>
        selectedItem?.id === item.id
          ? { ...item, is_active: item.is_active ? 0 : 1 }
          : item
      );
      setManagerAccountList(updatedList);
    }
    if (statusManagerAccountError) {
      showToast(statusManagerAccountError.message as string, "error");
    }
  }, [statusManagerAccountResponse, statusManagerAccountError]);

  useEffect(() => {
    if (statusResponse) {
      showToast(statusResponse.message as string, "success");
      handleInActive();
      viewPropertyApi();
    }
    if (statusError) {
      showToast(statusError.message as string, "error");
    }
  }, [statusResponse, statusError]);

  useEffect(() => {
    if (deleteResponse) {
      showToast(deleteResponse.message as string, "success");

      handleDelete();
      {
        isOrg ? navigate("/organizations") : navigate(uuid ? "/" + uuid + "/manage-properties" : "/manage-properties");
      }
    }
    if (deleteError) {
      showToast(deleteError.message as string, "error");
    }
  }, [deleteResponse, deleteError]);
  /**
   * @constant to initialise useFormik hook
   */
  const formik = useFormik({
    initialValues,
    validationSchema: managerAccounts,
    onSubmit: (values: managerAccountType) => {
      try {
        if (editModal) {
          const requestBody = {
            ...values,
            assign_property_uuids: updatedPropertyItems.map(
              (item) => item.value
            ),
            remove_property_uuids: removeItems.map((item) => item.value),
          };
          delete requestBody.email;
          editManagerAccountApi(requestBody);
        } else {
          addManagerAccountApi({
            ...values,
          });
        }
      } catch (e: any) {
        notifyBugsnagError(e, {
          api: "editManagerAccountApi || addManagerAccountApi",
        });
      }
    },
  });

  const initialCountData = [
    {
      icon: TipNumberIcon,
      title: "Total Number of Tips",
      amount: viewPropertyResponse?.data?.total_records
        ? viewPropertyResponse?.data?.total_records
        : removeDashed,
      key: "todayTipTransaction",
    },
    {
      icon: TransactionIcon,
      title: "Total Net Tip Amount",
      amount: viewPropertyResponse?.data?.net_tip_amount_count
        ? viewPropertyResponse?.data?.net_tip_amount_count.toLocaleString(
          "en-US",
          {
            style: "currency",
            currency: "USD",
          }
        )
        : removeDashed,
      key: "organizations",
      tipIcon: (
        <span
          onClick={() => {
            setShowTip(true);
            setDashboardTipItems([
              {
                label: "Total Trans. Amount",
                value: viewPropertyResponse?.data?.total_amount_count
                  ? viewPropertyResponse?.data?.total_amount_count.toLocaleString(
                    "en-US",
                    {
                      style: "currency",
                      currency: "USD",
                    }
                  )
                  : removeDashed,
              },
              {
                label: "Stripe Fee",
                value: viewPropertyResponse?.data?.stripe_fee_count
                  ? viewPropertyResponse?.data?.stripe_fee_count.toLocaleString(
                    "en-US",
                    {
                      style: "currency",
                      currency: "USD",
                    }
                  )
                  : removeDashed,
              },
              {
                label: "TopTipper Fee",
                value: viewPropertyResponse?.data?.top_tipper_fees_count
                  ? viewPropertyResponse?.data?.top_tipper_fees_count.toLocaleString(
                    "en-US",
                    {
                      style: "currency",
                      currency: "USD",
                    }
                  )
                  : removeDashed,
              },
              {
                label: "Net Tip Amount",
                value: viewPropertyResponse?.data?.net_tip_amount_count
                  ? viewPropertyResponse?.data?.net_tip_amount_count.toLocaleString(
                    "en-US",
                    {
                      style: "currency",
                      currency: "USD",
                    }
                  )
                  : removeDashed,
              },
            ]);
          }}
          style={{ marginLeft: "10px", marginBottom: "5px", cursor: "pointer" }}
        >
          {data?.role_id === 1 && tipIcon}
        </span>
      ),
    },
  ];

  /** Table heading for manager list */
  const tableHeadings = [
    { key: "first_name", label: "Name" },
    { key: "email", label: "Email" },
    { key: "show_phone", label: "Phone Number" },
  ];
  const actionButtonOptions = isOrg
    ? [
      {
        icon: <IoEyeOutline />,
        name: "View Assigned Properties",
        onClick: (item: managerAccountType) => {
          if (item) {
            navigate(
              `/organizations/view-assigned-properties/${item?.uuid}`,
              {
                state: {
                  first_name: item?.first_name,
                  email: item?.email,
                  phone_code: item?.phone_code,
                  phone_number: item?.phone_number,
                },
              }
            );
          }
        },
      },
      {
        icon: crossIcon,
        name: "Inactive",
        onClick: (item: managerAccountType) => {
          toggleActive();
          setSelectedItem(item);
        },
      },
    ]
    : [
      {
        icon: <IoEyeOutline />,
        name: "View Assigned Properties",
        onClick: (item: managerAccountType) => {
          if (item) {
            navigate(
              uuid
                ? "/" +
                uuid +
                `/manager-accounts/view-assigned-properties/${item?.uuid}`
                : `/manager-accounts/view-assigned-properties/${item?.uuid}`,
              {
                state: {
                  first_name: item?.first_name,
                  email: item?.email,
                  phone_code: item?.phone_code,
                  phone_number: item?.phone_number,
                },
              }
            );
          }
        },
      },
      {
        icon: edit,
        name: "Edit",
        onClick: (item: managerAccountType) => {
          setSelectedItem(item);
          formik.resetForm();
          toggleAddEdit();
          setEditModal(true);
          formik.setFieldValue("first_name", item.first_name);
          formik.setFieldValue("email", item.email);
          formik.setFieldValue("phone_number", item.phone_number);
          formik.setFieldValue(
            "assign_property_uuids",
            item.assign_property_uuids || []
          );
        },
      },
      {
        icon: crossIcon,
        name: "Inactive",
        onClick: (item: managerAccountType) => {
          toggleActive();
          setSelectedItem(item);
        },
      },
      {
        icon: deleteIcon,
        name: "Remove from Property",
        onClick: (item: managerAccountType) => {
          toggleDelete();
          setSelectedItem(item);
        },
      },
    ];

  /** useEffect for view property */
  useEffect(() => {
    viewPropertyApi();
  }, []);

  /** useEffect for unlink stripe account api */
  useEffect(() => {
    if (unlinkResponse) {
      showToast(unlinkResponse.message, "success");
      viewPropertyApi();
    } else if (unlinkError) {
      showToast(unlinkError.message, "error");
    }
    handleUnlink();
  }, [unlinkResponse, unlinkError]);

  useEffect(() => {
    if (linkResponse) {
      // showToast(linkResponse.message, "success");
      window.open(linkResponse.data?.url, "_blank");
    } else if (linkError) {
      showToast(linkError.message, "error");
    }
    handleUnlink();
  }, [linkResponse, linkError]);

  const formatAddress = (addressDetails: contactAddress) => {
    if (
      !addressDetails?.address &&
      !addressDetails?.city &&
      !addressDetails?.property_state_details?.name &&
      !addressDetails?.zip_code
    ) {
      return ""; // Return empty string if all fields are empty
    }

    return `${addressDetails?.address ? addressDetails?.address + ", " : ""}${addressDetails?.city ? addressDetails?.city + ", " : ""
      }${addressDetails?.property_state_details?.name
        ? addressDetails?.property_state_details?.name + ", "
        : ""
      }${addressDetails?.zip_code ? addressDetails?.zip_code + ", " : ""} `;
  };

  const ifHttps = (url: string) => {
    if (!url.includes("https") || !url.includes("http")) {
      // if (!url.includes("com")) {
      //   return "https://" + url + ".com";
      // } else {
      return "https://" + url;
      // }
    } else {
      return url;
    }
  };

  const DropdownItems = () => {
    return (
      <div
        style={{
          border: "1px solid #A9B0BF",
          paddingLeft: "11px",
          /* height: 44px; */
          width: "34px",
          borderRadius: "4px",
          backgroundColor: "#ededed",
        }}
      >
        <Dropdown
          align="end"
          // onClick={(e) => e.stopPropagation()}
          className={styles.dropDots}
        >
          <Dropdown.Toggle variant="light" id="dropdown-basic">
            ...
          </Dropdown.Toggle>

          <Dropdown.Menu
            style={{
              maxWidth: "max-content",
              minWidth: "150px",
              zIndex: "99",
            }}
          >
            {(data?.role_id === 2 || data?.role_id === 3) && (
              <Dropdown.Item
                onClick={() => {
                  navigate(
                    uuid
                      ? "/" + uuid + `/manage-properties/edit/${id}`
                      : `/manage-properties/edit/${id}`
                  );
                }}
              >
                {" "}
                {editIcon}
                Edit
              </Dropdown.Item>
            )}
            <Dropdown.Item
              onClick={() => {
                setOpenInActive(true);
              }}
            >
              {" "}
              {crossIcon}
              {viewPropertyResponse?.data?.is_active
                ? "Inactivate"
                : "Activate"}
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() => {
                setOpenDelete(true);
              }}
            >
              {" "}
              {deleteIcon}
              Delete
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() => {
                setOpenUnlinkModal(true);
              }}
            >
              {" "}
              {linkStripe}
              {linkUnlinkStripe ? 'Remove Property Stripe account' : 'Add Property Stripe account'}
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    );
  };

  return (
    <DashboardLayout>
      {viewPropertyLoading ? (
        <ReactLoader />
      ) : (
        <div className={styles.dash_page}>
          <Container>
            <Row className="g-xl-4 g-3">
              <Col xxl={12} xl={12}>
                <div
                  className={`${styles.dashboardHeading} ${styles.orgnaizationDetails}`}
                >
                  <div className={styles.alltips}>
                    <h2
                      className="d-flex justify-content-center gap-2"
                      style={{ lineHeight: "normal" }}
                    >
                      Property Information <DropdownItems />{" "}
                    </h2>
                    <p>Here is the information about your property.</p>
                  </div>
                  <div className={styles.search}>
                    <CommonButton
                      title="View QR Code"
                      icon={eyes}
                      onClick={() => {
                        openQrCode(viewPropertyResponse?.data?.qr_code);
                      }}
                    />

                    <CommonButton
                      title={"Copy Tip URL"}
                      icon={copy}
                      onClick={() =>
                        copyToClipBoard(viewPropertyResponse?.data?.copy_link)
                      }
                    />
                    {isOrg ? (
                      <Link
                        to="/organizations/view-tip-transactions"
                        state={{
                          name: viewPropertyResponse?.data?.name,
                          uuid: viewPropertyResponse?.data?.uuid,
                        }}
                      >
                        <CommonButton
                          title="  View Tip Transactions"
                          icon={eyes}
                        />
                      </Link>
                    ) : (
                      <Link
                        to={
                          uuid
                            ? "/" +
                            uuid +
                            "/manage-properties/view-property-tips/view-tip-transactions"
                            : "/manage-properties/view-property-tips/view-tip-transactions"
                        }
                        state={{
                          name: viewPropertyResponse?.data?.name,
                          uuid: viewPropertyResponse?.data?.uuid,
                        }}
                      >
                        <CommonButton
                          title="  View Tip Transactions"
                          icon={eyes}
                        />
                      </Link>
                    )}
                  </div>
                </div>
              </Col>
            </Row>

            <div className={styles.cardsWrap}>
              {initialCountData?.map((item) => (
                <div className={styles.card_outer}>
                  <div key={item.key} className={styles.card}>
                    <div className={styles.icon}>{item.icon}</div>
                    <div className={styles.content}>
                      <div className={styles.title}>{item.title}</div>
                      <div className={styles.amount}>
                        {item.amount}
                        {item.tipIcon && item.tipIcon}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>

            <Row className="g-xl-4 g-3">
              <Col xxl={12} xl={12}>
                <div className={styles.dashboardTable}>
                  <Row>
                    <Col sm={12} md={3}>
                      <div className={styles.LogoName}>
                        <div
                          className={styles.LogoBg}
                          style={{
                            overflow: "hidden",
                            backgroundColor: viewPropertyResponse?.data
                              ?.theme_color
                              ? viewPropertyResponse?.data?.theme_color
                              : viewPropertyResponse?.data
                                ?.property_organization_details?.theme_color,
                          }}
                        >
                          {viewPropertyResponse?.data?.property_media_details
                            ?.url ||
                            viewPropertyResponse?.data
                              ?.property_organization_details
                              ?.organization_media_details?.url ? (
                            <img
                              src={
                                viewPropertyResponse?.data
                                  ?.property_media_details?.url
                                  ? viewPropertyResponse?.data
                                    ?.property_media_details?.url
                                  : viewPropertyResponse?.data
                                    ?.property_organization_details
                                    ?.organization_media_details?.url
                              }
                              alt="logos"
                            />
                          ) : (
                            "No Image"
                          )}
                        </div>
                        <div className={styles.NameText}>
                          <h6>S</h6>
                          <h5
                            className={
                              viewPropertyResponse?.data?.property_stripe_status === 1
                                ? ""
                                : viewPropertyResponse?.data?.property_stripe_status === 2
                                  ? ""
                                  : viewPropertyResponse?.data?.property_stripe_status === 3
                                    ? styles.error
                                    : viewPropertyResponse?.data?.property_stripe_status === 4
                                      ? styles.error
                                      : viewPropertyResponse?.data?.property_stripe_status === 5
                                        ? styles.error
                                        : styles.error
                            }
                          >
                            <span />
                            {viewPropertyResponse?.data?.property_stripe_status === 1
                              ? "Complete"
                              : viewPropertyResponse?.data?.property_stripe_status === 2
                                ? "Enabled"
                                : viewPropertyResponse?.data?.property_stripe_status === 3
                                  ? "Error"
                                  : viewPropertyResponse?.data?.property_stripe_status === 4
                                    ? "Pending"
                                    : viewPropertyResponse?.data?.property_stripe_status === 5
                                      ? "InProgress"
                                      : "Not Setup"} <div className={styles.editStripeBox} onClick={() => propertyLinkStripeApi()}><p className={styles.editStripe}>{editIcon}</p></div>
                          </h5>
                        </div>
                        <div className={styles.copyText}>
                          <span>
                            {viewPropertyResponse?.data?.property_stripe_connect_account_id
                              ? viewPropertyResponse?.data?.property_stripe_connect_account_id
                              : removeDashed}
                          </span>
                        </div>
                      </div>
                    </Col>
                    <Col sm={12} md={9}>
                      {viewPropertyLoading ? (
                        <ReactLoader />
                      ) : (
                        <div className={styles.rightSides}>
                          <div className={styles.rightName}>
                            <h6>
                              Property Name{" "}
                              <span
                                className={
                                  viewPropertyResponse?.data?.is_active
                                    ? styles.green
                                    : styles.red
                                }
                              >
                                {" "}
                              </span>{" "}
                              {viewPropertyResponse?.data?.is_active
                                ? "Active"
                                : "Inactive"}
                            </h6>
                            <h2
                              data-bs-toggle="tooltip"
                              data-bs-placement="top-start"
                              title={viewPropertyResponse?.data?.name}
                              style={{ wordBreak: "break-word" }}
                            >
                              {viewPropertyResponse?.data?.name}
                            </h2>
                          </div>

                          <Row>
                            <Col xxl={4} lg={6} className="mb-3">
                              <div className={styles.cardbox}>
                                <h5 className={styles.property_head}>
                                  Property ID
                                </h5>
                                <h4 className={styles.property_val}>
                                  {viewPropertyResponse?.data?.id ?? removeDashed}
                                </h4>
                              </div>
                            </Col>
                            <Col xxl={4} lg={6} className="mb-3">
                              <div className={styles.cardbox}>
                                <h5 className={styles.property_head}>
                                  Organization Name
                                </h5>
                                <h4
                                  className={styles.property_val}
                                  data-bs-toggle="tooltip"
                                  data-bs-placement="top-start"
                                  title={
                                    viewPropertyResponse?.data
                                      ?.property_organization_details?.name
                                  }
                                >
                                  {viewPropertyResponse?.data
                                    ?.property_organization_details?.name ??
                                    removeDashed}
                                </h4>
                              </div>
                            </Col>
                            {/* <Col xxl={3} lg={6} className="mb-3">
                              <div className={styles.cardbox}>
                                <h5 className={styles.property_head}>
                                  Property Email
                                </h5>
                                <h4
                                  className={styles.property_val}
                                  data-bs-toggle="tooltip"
                                  data-bs-placement="top-start"
                                  title={
                                    viewPropertyResponse?.data?.email ?? removeDashed
                                  }
                                >
                                  {viewPropertyResponse?.data?.email ?? removeDashed}
                                </h4>
                              </div>
                            </Col> */}
                            <Col xxl={4} lg={6} className="mb-3">
                              <div className={styles.cardbox}>
                                <h5 className={styles.property_head}>
                                  Property Phone
                                </h5>
                                <h4 className={styles.property_val}>
                                  {viewPropertyResponse?.data?.phone_number
                                    ? `${viewPropertyResponse?.data
                                      ?.property_country_details?.phone_code
                                      ? `${viewPropertyResponse?.data?.property_country_details?.phone_code} `
                                      : ""
                                    }${viewPropertyResponse?.data?.phone_number
                                    }`
                                    : removeDashed}
                                </h4>
                              </div>
                            </Col>

                            <Col xxl={6} lg={6} className="mb-3">
                              {" "}
                              <div className={styles.cardbox}>
                                <h5 className={styles.property_head}>
                                  Address
                                </h5>
                                <h4
                                  data-bs-toggle="tooltip"
                                  data-bs-placement="top-start"
                                  title={
                                    formatAddress(viewPropertyResponse?.data) +
                                    " United States"
                                  }
                                  className={`${styles.property_val} "`}
                                >
                                  {formatAddress(viewPropertyResponse?.data)}
                                  United States
                                </h4>
                              </div>
                            </Col>
                            <Col xxl={3} lg={6} className="mb-3">
                              {" "}
                              <div
                                className={`${styles.flex} ${styles.cardbox}`}
                              >
                                <div>
                                  <h5 className={styles.property_head}>
                                    Background Color
                                  </h5>
                                  <h4 className={styles.property_val}>
                                    {viewPropertyResponse?.data?.theme_color
                                      ? viewPropertyResponse?.data?.theme_color
                                      : viewPropertyResponse?.data
                                        ?.property_organization_details
                                        ?.theme_color}
                                  </h4>
                                </div>
                                <div
                                  style={{
                                    width: "34px",
                                    height: "34px",
                                    borderRadius: "4px",
                                    border: "0.5px solid grey",
                                    backgroundColor: viewPropertyResponse?.data
                                      ?.theme_color
                                      ? viewPropertyResponse?.data?.theme_color
                                      : viewPropertyResponse?.data
                                        ?.property_organization_details
                                        ?.theme_color,
                                  }}
                                ></div>
                              </div>
                            </Col>
                            <Col xxl={3} lg={6} className="mb-3">
                              {" "}
                              <div
                                className={`${styles.flex} ${styles.cardbox}`}
                              >
                                <div>
                                  <h5 className={styles.property_head}>
                                    Font Color
                                  </h5>
                                  <h4 className={styles.property_val}>
                                    {viewPropertyResponse?.data?.font_color
                                      ? viewPropertyResponse?.data?.font_color
                                      : viewPropertyResponse?.data
                                        ?.property_organization_details
                                        ?.font_color}
                                  </h4>
                                </div>
                                <div
                                  style={{
                                    width: "34px",
                                    height: "34px",
                                    borderRadius: "4px",
                                    border: "0.5px solid grey",
                                    backgroundColor: viewPropertyResponse?.data
                                      ?.font_color
                                      ? viewPropertyResponse?.data?.font_color
                                      : viewPropertyResponse?.data
                                        ?.property_organization_details
                                        ?.font_color,
                                  }}
                                ></div>
                              </div>
                            </Col>
                            <Col xxl={6} lg={6} className="mb-3">
                              {" "}
                              <div className={styles.cardbox}>
                                <h5 className={styles.property_head}>
                                  Google Site URL
                                </h5>
                                <h4
                                  className={styles.property_val}
                                  data-bs-toggle="tooltip"
                                  data-bs-placement="top-start"
                                  title={
                                    viewPropertyResponse?.data?.google_site_url
                                      ? viewPropertyResponse?.data
                                        ?.google_site_url
                                      : ""
                                  }
                                >
                                  {viewPropertyResponse?.data
                                    ?.google_site_url ? (
                                    <a
                                      href={ifHttps(
                                        viewPropertyResponse?.data
                                          ?.google_site_url
                                      )}
                                      target="_blank"
                                      rel="noreferrer"
                                    >
                                      {
                                        viewPropertyResponse?.data
                                          ?.google_site_url
                                      }
                                    </a>
                                  ) : (
                                    removeDashed
                                  )}
                                </h4>
                              </div>
                            </Col>
                            <Col xxl={6} lg={6} className="mb-3">
                              {" "}
                              <div className={styles.cardbox}>
                                <h5 className={styles.property_head}>
                                  Trip Advisor URL
                                </h5>
                                <h4
                                  className={styles.property_val}
                                  data-bs-toggle="tooltip"
                                  data-bs-placement="top-start"
                                  title={
                                    viewPropertyResponse?.data?.trip_advisor_url
                                      ? viewPropertyResponse?.data
                                        ?.trip_advisor_url
                                      : ""
                                  }
                                >
                                  {viewPropertyResponse?.data
                                    ?.trip_advisor_url ? (
                                    <a
                                      href={ifHttps(
                                        viewPropertyResponse?.data
                                          ?.trip_advisor_url
                                      )}
                                      target="_blank"
                                      rel="noreferrer"
                                    >
                                      {
                                        viewPropertyResponse?.data
                                          ?.trip_advisor_url
                                      }
                                    </a>
                                  ) : (
                                    removeDashed
                                  )}
                                </h4>
                              </div>
                            </Col>
                            <Col xxl={6} lg={6} className="mb-3">
                              {" "}
                              <div className={styles.cardbox}>
                                <h5 className={styles.property_head}>
                                  Yelp URL
                                </h5>
                                <h4
                                  className={styles.property_val}
                                  data-bs-toggle="tooltip"
                                  data-bs-placement="top-start"
                                  title={
                                    viewPropertyResponse?.data?.yelp_url
                                      ? viewPropertyResponse?.data?.yelp_url
                                      : ""
                                  }
                                >
                                  {viewPropertyResponse?.data?.yelp_url ? (
                                    <a
                                      href={ifHttps(
                                        viewPropertyResponse?.data?.yelp_url
                                      )}
                                      target="_blank"
                                      rel="noreferrer"
                                    >
                                      {viewPropertyResponse?.data?.yelp_url}
                                    </a>
                                  ) : (
                                    removeDashed
                                  )}
                                </h4>
                              </div>
                            </Col>
                            <Col xxl={6} lg={6} className="mb-3">
                              {" "}
                              <div className={styles.cardbox}>
                                <h5 className={styles.property_head}>
                                  Facebook URL
                                </h5>
                                <h4
                                  className={styles.property_val}
                                  data-bs-toggle="tooltip"
                                  data-bs-placement="top-start"
                                  title={
                                    viewPropertyResponse?.data?.facebook_url
                                      ? viewPropertyResponse?.data?.facebook_url
                                      : ""
                                  }
                                >
                                  {viewPropertyResponse?.data?.facebook_url ? (
                                    <a
                                      href={ifHttps(
                                        viewPropertyResponse?.data?.facebook_url
                                      )}
                                      target="_blank"
                                      rel="noreferrer"
                                    >
                                      {viewPropertyResponse?.data?.facebook_url}
                                    </a>
                                  ) : (
                                    removeDashed
                                  )}
                                </h4>
                              </div>
                            </Col>
                          </Row>
                        </div>
                      )}
                    </Col>
                  </Row>
                </div>
              </Col>

              <ReactTable
                headings={tableHeadings}
                data={managerAccountList || []}
                actionColumn
                actionButtonOptions={actionButtonOptions}
                currentPage={currentPage}
                itemPerPage={pageLimit}
                totalItems={totalRecords}
                setPerPageLimit={setPageLimit}
                setCurrentPage={setCurrentPage}
                pageLimit={pageLimit}
                pagination={true}
              >
                <PageHeading heading="Manager Accounts" subHeading="">
                  <div className={styles.search}>
                    <div className={styles.searchField}>
                      <InputField
                        name="search_manager_name"
                        icon={searchIcon}
                        placeholder="Search"
                        value={searchValue}
                        onChange={(e) => {
                          setCurrentPage(1);
                          setSearchValue(e.target.value);
                        }}
                        autoComplete="off"
                      />
                      {searchValue ? (
                        <span
                          className={styles.crossIcon}
                          onClick={() => setSearchValue("")}
                        >
                          <RxCross1 />
                        </span>
                      ) : null}
                    </div>
                    {!isOrg && (
                      <ThemeButton
                        variant="primary"
                        onClick={() => {
                          toggleAddEdit();
                          setEditModal(false);
                          formik.resetForm();
                        }}
                      >
                        Add New Manager
                      </ThemeButton>
                    )}
                  </div>
                </PageHeading>
              </ReactTable>

              <ConfirmationModal
                handleToggle={handleUnlink}
                title={`Are you sure you want to ${linkUnlinkStripe ? 'Unlink Stripe' : 'Link Stripe'} account ? `}
                show={openUnlinkModal}
                loading={linkUnlinkStripe ? unlinkLoading : linkLoading}
                actionTitle={linkUnlinkStripe ? "Unlink Stripe Account" : "Link Stripe Account"}
                confirm={() => linkUnlinkStripe ? propertyUnLinkStripeApi() : propertyLinkStripeApi()}
              />

              <AddAccountModal
                propertyUuid={{
                  label: viewPropertyResponse?.data?.name,
                  value: id as string,
                }}
                show={show}
                handleClose={toggleAddEdit}
                title={editModal ? "Edit Account" : "Add Account"}
                editModal={editModal}
                loading={addManagerAccountLoading || editManagerAccountLoading}
                formik={formik}
                updatedPropertyItems={updatedPropertyItems}
                removeItems={removeItems}
                setUpdatedPropertyItems={setUpdatedPropertyItems}
                setRemoveItems={setRemoveItems}
              />

              <ConfirmationModal
                handleToggle={toggleDelete}
                title={"Are you sure you want to remove this manager account?"}
                show={showDelete}
                loading={updateManagerAccountLoading}
                confirm={() =>
                  updateManagerAccountApi({
                    remove_property_uuids: [viewPropertyResponse?.data?.uuid],
                  })
                }
                actionTitle="Remove"
              />

              <InactiveActiveModal
                title={`Are you sure you want to ${selectedItem?.is_active ? "deactivate" : "activate"
                  }
                  this manager account?`}
                handleToggle={toggleActive}
                show={showActive}
                heading={selectedItem?.is_active ? "Deactivate" : "Activate"}
                loading={statusManagerAccountLoading}
                confirm={() =>
                  statusManagerAccountApi({
                    is_active: selectedItem?.is_active ? 0 : 1,
                  })
                }
              />
              <InactiveActiveModal
                handleToggle={handleInActive}
                title={`Are you sure you want to ${viewPropertyResponse?.data?.is_active
                  ? "deactivate"
                  : "activate"
                  }
                    this property?`}
                show={openInActive}
                heading={
                  viewPropertyResponse?.data?.is_active
                    ? "Deactivate"
                    : "Activate"
                }
                loading={statusLoading}
                confirm={() =>
                  statusApi({
                    is_active: viewPropertyResponse?.data?.is_active ? 0 : 1,
                  })
                }
              />
              <ConfirmationModal
                handleToggle={handleDelete}
                title="Are you sure you want to delete
              this property?"
                show={openDelete}
                loading={deleteLoading}
                confirm={() => deleteApi()}
              />
              <TipAmountModal
                show={showTip}
                handleClose={handleTipClose}
                title="Tip Amount Information"
                tipItems={dashboardTipItems}
              >
                <ThemeButton onClick={() => handleTipClose()}>
                  Close
                </ThemeButton>
              </TipAmountModal>
              <div>
                <PropertyServices
                  uuid={id}
                  orgId={
                    viewPropertyResponse?.data?.property_organization_details
                      ?.id
                  }
                />
              </div>
            </Row>
          </Container>
        </div>
      )}
    </DashboardLayout>
  );
};
export default ManageViewProperty;
