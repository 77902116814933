import { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useLocation, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { deleteIcon } from "../../../../assests/Icons/icon";
import DashboardLayout from "../../../../components/layouts/DashboardLayout";
import ConfirmationModal from "../../../../components/modals/ConfirmationModal/ConfirmationModal";
import PageHeading from "../../../../components/pageHeading";
import ReactTable from "../../../../components/reactTable/ReactTable";
import useFetch from "../../../../hooks/useFetch";
import styles from "../property/view-assigned-properties.module.scss";
import {
  notifyBugsnagError,
  removeDashed,
  showToast,
} from "../../../../common/utils/Functions";

interface viewAssignedPropertyType {
  property_detail?: {
    id?: string;
    name?: string;
    address?: string;
    uuid?: string;
    city?: string;
    state?: number;
    zip_code?: number;
    property_state_details?: {
      name: string;
    };
  };
}

const ManageAssignedProperties = () => {
  const { id } = useParams();
  const [viewAssinedProperty, setViewAssignedProperty] = useState();

  const location = useLocation();
  const { state } = location;
  /**
   * @Pagination states
   * 1. pageLimit    :- store limit of data that will render on current page
   * 2. totalRecords :- store total number of data in service api
   * 3. currentPage  :- store current page number
   */
  const [pageLimit, setPageLimit] = useState<number>(10);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [showDelete, setShowDelete] = useState(false); //  It's use to open "Delete" modal
  const [removePropertyUuid, setRemovePropertyUuid] = useState<string>("");
  const toggleDelete = () => setShowDelete(!showDelete);

  /** --------3. @editManagerAccountApi ------ */
  const [
    editManagerAccountApi,
    {
      response: editManagerAccountResponse,
      loading: editManagerAccountLoading,
      error: editManagerAccountError,
    },
  ] = useFetch(`/manager/update/${id}`, {
    method: "put",
  });

  /** Api definition */
  const [
    viewAssignedPropertyApi,
    { response: viewResponse, loading: viewLoading, error: viewError },
  ] = useFetch("/manager/view-assigned-properties", { method: "post" });

  useEffect(() => {
    const timer = setTimeout(() => {
      try {
        viewAssignedPropertyApi({
          start: (currentPage - 1) * pageLimit,
          limit: pageLimit,
          manager_uuid: id,
        });
      } catch (e: any) {
        notifyBugsnagError(e, {
          api: "viewAssignedPropertyApi",
        });
      }
    }, 500);
    return () => clearTimeout(timer);
  }, [currentPage, pageLimit]);

  useEffect(() => {
    if (viewResponse) {
      setTotalRecords(viewResponse.data?.totalRecords);
      const updatedList = viewResponse.data?.list.map(
        (item: viewAssignedPropertyType) => ({
          id: item.property_detail?.id,
          name: item.property_detail?.name,
          address: `${item?.property_detail?.address
              ? item?.property_detail?.address + ", "
              : ""
            } ${item?.property_detail?.city
              ? item?.property_detail?.city + ", "
              : ""
            } ${item?.property_detail?.property_state_details?.name
              ? item?.property_detail?.property_state_details?.name + ", "
              : ""
            } ${item?.property_detail?.zip_code
              ? item?.property_detail?.zip_code + ", "
              : ""
            } United States`,
          uuid: item?.property_detail?.uuid,
        })
      );
      setViewAssignedProperty(updatedList);
    }
    if (viewError) {
      showToast(viewError.message as string, "error");
    }
  }, [viewResponse, viewError]);

  const tableHeadings = [
    { key: "id", label: "Property ID" },
    { key: "name", label: " Property Name" },
    { key: "address", label: "Property Address" },
  ];
  const actionButtonOptions = [
    {
      name: "Remove from Property",
      icon: deleteIcon,
      onClick: (item: any) => {
        setShowDelete(true);
        setRemovePropertyUuid(item.uuid as string);
      },
    },
  ];

  useEffect(() => {
    if (editManagerAccountResponse) {
      showToast(editManagerAccountResponse.message as string, "success");
      try {
        viewAssignedPropertyApi({
          start: (currentPage - 1) * pageLimit,
          limit: pageLimit,
          manager_uuid: id,
        });
      } catch (e: any) {
        notifyBugsnagError(e, {
          api: "viewAssignedPropertyApi",
        });
      }
      toggleDelete();
    }
    if (editManagerAccountError) {
      showToast(editManagerAccountError.message as string, "error");

      toggleDelete();
    }
  }, [editManagerAccountResponse, editManagerAccountError]);

  return (
    <DashboardLayout>
      <div className={styles.dash_page}>
        <Container>
          <Row className="g-xl-4 g-3">
            <Col xxl={12} xl={12}>
              <PageHeading
                heading="View Assigned Properties"
                subHeading="Here you can view all properties of manager."
              ></PageHeading>
            </Col>

            <Col xxl={12} xl={12}>
              <div className={styles.manageCards}>
                <div className={styles.cardsCards}>
                  <h2>Manager Name</h2>
                  <p style={{ wordBreak: "break-word" }}>{state.first_name}</p>
                </div>
                <div
                  className={styles.cardsCards}
                  style={{
                    minWidth: "max-content",
                    wordBreak: "keep-all",
                  }}
                >
                  <h2>Manager Email</h2>
                  <p style={{ wordBreak: "break-word" }}>{state.email}</p>
                </div>
                <div className={styles.cardsCards}>
                  <h2>Manager Phone</h2>
                  <p style={{ wordBreak: "break-word" }}>
                    {" "}
                    {state?.phone_number && state?.phone_code}{" "}
                    {state?.phone_number ? state?.phone_number : removeDashed}
                  </p>
                </div>
              </div>
            </Col>

            <ReactTable
              headings={tableHeadings}
              data={viewAssinedProperty || []}
              actionColumn
              loading={viewLoading || !viewAssinedProperty}
              actionButtonOptions={actionButtonOptions}
              currentPage={currentPage}
              itemPerPage={pageLimit}
              totalItems={totalRecords}
              setPerPageLimit={setPageLimit}
              setCurrentPage={setCurrentPage}
              pagination={true}
            />
            <ConfirmationModal
              handleToggle={toggleDelete}
              title={"Are you sure you want to remove this property account?"}
              show={showDelete}
              loading={editManagerAccountLoading}
              confirm={() =>
                editManagerAccountApi({
                  remove_property_uuids: [removePropertyUuid],
                })
              }
              actionTitle="Remove"
            />
          </Row>
        </Container>
      </div>
    </DashboardLayout>
  );
};
export default ManageAssignedProperties;
