import { Col, Dropdown, Row } from "react-bootstrap";
import ModalLayout from "../../../layouts/ModalLayout";
import styles from "./EmployeeModal.module.scss";
import { useNavigate } from "react-router-dom";
import { deleteIcon, editIcon } from "../../../../assests/Icons/icon";
import { propertyEmployeeListType } from "../../../../types/manageProperty";
import { useEffect, useState } from "react";
import ConfirmationModal from "../../../modals/ConfirmationModal/ConfirmationModal";
import useFetch from "../../../../hooks/useFetch";
import {
  notifyBugsnagError,
  removeDashed,
  showToast,
} from "../../../../common/utils/Functions";

// interface props {
//   PropertyEmployeeListApi: any;
//   property_uuid?: string;
//   isModal?: boolean;
//   setEmployeeValues?: any;
//   openEditModal?: () => void;
//   setIsEditEmployee?: any;
//   setEmployeeUuid?: any;
//   limit?: any;
// }

const EmployeeView = ({
  show,
  data,
  setData,
  handleShow,
  openEditModal,
  setEmployeeValues,
  setIsEditEmployee,
  PropertyEmployeeListApi,
  property_uuid,
  limit,
}: {
  show: boolean;
  data: any;
  setData: any;
  handleShow: any;
  openEditModal?: () => void;
  setEmployeeValues?: any;
  setIsEditEmployee?: any;
  PropertyEmployeeListApi: any;
  property_uuid?: string;
  limit?: any;
}) => {
  const handleModalClose = () => {
    handleShow(false);
    setData(null);
  };
  const navigate = useNavigate();
  const [uuid2, setUuid2] = useState("");
  const [openDelete, setOpenDelete] = useState<boolean>(false);
  const handleDelete = () => setOpenDelete(false);

  const DropdownItems = () => {
    return (
      <div
        style={{
          border: "1px solid #A9B0BF",
          paddingLeft: "11px",
          /* height: 44px; */
          width: "34px",
          borderRadius: "4px",
          backgroundColor: "#ededed",
        }}
      >
        <Dropdown align="end" className={styles.dropDots}>
          <Dropdown.Toggle variant="light" id="dropdown-basic">
            ...
          </Dropdown.Toggle>

          <Dropdown.Menu
            style={{
              maxWidth: "max-content",
              minWidth: "150px",
              zIndex: "99",
            }}
          >
            <Dropdown.Item
              onClick={(item) => {
                !!openEditModal && openEditModal();
                setEmployeeValues(data);
                setIsEditEmployee(true);
                handleShow(false);
              }}
            >
              {" "}
              {editIcon}
              Edit
            </Dropdown.Item>

            <Dropdown.Item
              onClick={(e) => {
                setUuid2(data?.uuid);
                setOpenDelete(true);
              }}
            >
              {" "}
              {deleteIcon}
              Delete
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    );
  };
  const [
    PropertyEmployeeDeleteApi,
    {
      response: PropertyEmployeeDelRes,
      error: PropertyEmployeeDelError,
      loading: PropertyEmployeeDelLoading,
    },
  ] = useFetch(`propertyemployees/delete/${uuid2}`, {
    method: "delete",
  });
  useEffect(() => {
    if (PropertyEmployeeDelRes) {
      showToast(PropertyEmployeeDelRes.message as string, "success");
      handleShow(false);
      try {
        PropertyEmployeeListApi({
          property_uuid,
          start: 0,
          limit: -1,
        });
      } catch (e: any) {
        notifyBugsnagError(e, {
          api: "PropertyEmployeeListApi",
        });
      }
      handleDelete();
    }
    if (PropertyEmployeeDelError) {
      showToast(PropertyEmployeeDelError.message as string, "error");
    }
  }, [PropertyEmployeeDelError, PropertyEmployeeDelRes]);
  return (
    <ModalLayout
      title={
        <div
          style={{
            display: "flex",
            gap: 10,
            alignItems: "center",
          }}
        >
          Employee Detail <DropdownItems />
        </div>
      }
      show={show}
      size={850}
      handleToggle={handleModalClose}
    >
      <Row>
        <Col xs={6}>
          <h3
            style={{
              fontSize: 16,
            }}
          >
            Name
          </h3>
          <p
            style={{
              wordBreak: "break-word",
              marginTop: 2,
            }}
          >
            {data?.name ? data?.name : removeDashed}
          </p>
        </Col>
        <Col xs={6}>
          <h3
            style={{
              fontSize: 16,
            }}
          >
            Service
          </h3>
          <p
            style={{
              wordBreak: "break-word",
            }}
          >
            {data?.property_employees_services_details[0]?.property_service_name
              ? data?.property_employees_services_details[0]
                ?.property_service_name
              : removeDashed}
          </p>
        </Col>
        <Col xs={6}>
          <h3
            style={{
              fontSize: 16,
            }}
          >
            Email
          </h3>
          <p
            style={{
              wordBreak: "break-word",
            }}
          >
            {data?.email ? data?.email : removeDashed}
          </p>
        </Col>
        <Col xs={6}>
          <h3
            style={{
              fontSize: 16,
            }}
          >
            Phone
          </h3>
          <p
            style={{
              wordBreak: "break-word",
            }}
          >
            {" "}
            {data?.phone_number &&
              data?.property_employee_phone_code_details?.phone_code}{" "}
            {data?.phone_number ? data?.phone_number : removeDashed}
          </p>
        </Col>
      </Row>
      <ConfirmationModal
        handleToggle={handleDelete}
        title="Are you sure you want to delete
              this employee?"
        show={openDelete}
        loading={PropertyEmployeeDelLoading}
        confirm={() => PropertyEmployeeDeleteApi()}
      />
    </ModalLayout>
  );
};

export default EmployeeView;
