import { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import searchIcon from "../../assests/svg/searchIcon.svg";

import { IoEyeOutline } from "react-icons/io5";
import { RxCross1 } from "react-icons/rx";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  OrganizationIcon,
  QrIcon,
  TipAmountIcon,
  TipNumberIcon,
  TipRevenueIcon,
  TipperIcon,
  TransactionIcon,
  propertyDashIcon,
} from "../../assests/Icons/dashboardIcons";
import {
  clearUserLocalData,
  getCurrentUserData,
  notifyBugsnagError,
  showToast,
} from "../../common/utils/Functions";
import DashboardLayout from "../../components/layouts/DashboardLayout";
import PageHeading from "../../components/pageHeading";
import ReactTable from "../../components/reactTable/ReactTable";
import InputField from "../../components/theme/InputField";
import ThemeButton from "../../components/theme/ThemeButton";
import useFetch from "../../hooks/useFetch";
import { AppDispatch, RootState } from "../../redux/store";
import styles from "./userDashboard.module.scss";
import StripeSetUpIndication from "../../components/stripeSetupIndication/SetUpSuccess";
import SetUpSuccess from "../../components/stripeSetupIndication/SetUpSuccess";
import SetUpError from "../../components/stripeSetupIndication/SetUpError";

const Dashboard = () => {
  const { uuid } = useParams();
  const LocalData = getCurrentUserData(`${uuid}`);
  const initialCountData =
    LocalData?.role_id === 3
      ? [
        {
          icon: propertyDashIcon,
          title: "Manage Properties",
          key: "manageProperties",
          link: uuid
            ? "/" + uuid + "/manage-properties"
            : "/manage-properties",
        },
        {
          icon: QrIcon,
          title: "QR Codes & Links",
          key: "qrCodes",
          link: uuid ? "/" + uuid + "/QR-Codes-&-links" : "/QR-Codes-&-links",
        },
      ]
      : [
        {
          icon: TransactionIcon,
          title: "Tip Transactions Today",
          amount: 0,
          key: "todayTipTransaction",
          onclick: () => {
            navigate(uuid ? "/" + uuid + "/reporting" : "/reporting", {
              state: {
                filterValue: { value: "today", label: "Today" },
              },
            });
          },
        },
        {
          icon: OrganizationIcon,
          title: "Properties",
          amount: 0,
          key: "properties",
          onclick: () => {
            navigate(
              uuid ? "/" + uuid + "/manage-properties" : "/manage-properties"
            );
          },
        },
        {
          icon: TipperIcon,
          title: "Tippers",
          amount: 0,
          key: "tippers",
          onclick: () => {
            navigate(uuid ? "/" + uuid + "/reporting" : "/reporting");
          },
        },
        {
          icon: TipNumberIcon,
          title: "Total Number of Tips",
          amount: 0,
          key: "totalTips",
          onclick: () => {
            navigate(uuid ? "/" + uuid + "/reporting" : "/reporting");
          },
        },
        {
          icon: TipAmountIcon,
          title: "Net Tip Amount",
          amount: 0,
          key: "totalTipperAmt",
          onclick: () => {
            navigate(uuid ? "/" + uuid + "/reporting" : "/reporting");
          },
        },
        {
          icon: TipRevenueIcon,
          title: "Average Tip Amount",
          amount: 0,
          key: "average_tip_amount",
          onclick: () => {
            navigate(uuid ? "/" + uuid + "/reporting" : "/reporting");
          },
        },
      ];
  // const initialCountData = [
  //   {
  //     icon: TransactionIcon,
  //     title: "Tip Transactions Today",
  //     amount: 0,
  //     key: "todayTipTransaction",
  //   },
  //   {
  //     icon: OrganizationIcon,
  //     title: "Properties",
  //     amount: 0,
  //     key: "properties",
  //   },
  //   {
  //     icon: TipperIcon,
  //     title: "Tippers",
  //     amount: 0,
  //     key: "tippers",
  //   },
  //   {
  //     icon: TipNumberIcon,
  //     title: "Total Number of Tips",
  //     amount: 0,
  //     key: "totalTips",
  //   },
  //   {
  //     icon: TipAmountIcon,
  //     title: "Net Tip Amount",
  //     amount: 0,
  //     key: "totalTipperAmt",
  //   },
  //   {
  //     icon: TipRevenueIcon,
  //     title: "Avergae Tip Amount",
  //     amount: 0,
  //     key: "average_tip_amount",
  //   },
  // ];
  const [dashbaordData, setDashboardData] = useState();

  const [searchValue, setSearchValue] = useState("");
  /**
   * @Pagination states
   * 1. pageLimit    :- store limit of data that will render on current page
   * 2. totalRecords :- store total number of data in partners api
   * 3. currentPage  :- store current page number
   */
  const [pageLimit, setPageLimit] = useState<number>(10);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const { data } = useSelector((store: RootState) => store.userData);

  /** Api definition */
  const [
    loginAsAdminApi,
    {
      response: loginAsAdminRes,
      loading: loginAsAdminLoading,
      error: loginAsAdminErr,
    },
  ] = useFetch(`/auth/admin-org-login?uuid=${data?.uuid}`);

  const urlForAdmin = (prop_name?: string) => {
    if (uuid) {
      return (
        "/" +
        uuid +
        `/manage-properties/view-property-tips/view-tip-transactions`
      );
    } else {
      return `/manage-properties/view-property-tips/view-tip-transactions`;
    }
  };
  /**
   * @Array :- for table headings that passed as a prop to React-Table component
   */
  const tableHeadings = [
    // {
    //   key: "propertyId",
    //   label: "Property  ID",
    //   onClick: (item: any) => {
    //     navigate(urlForAdmin(), {
    //       state: {
    //         uuid: item?.propertyUuid,
    //         name: item?.name,
    //       },
    //     });
    //   },
    // },
    {
      key: "name",
      label: "Property Name",
      onClick: (item: any) => {
        navigate(urlForAdmin(), {
          state: {
            uuid: item?.propertyUuid,
            name: item?.name,
          },
        });
      },
    },
    {
      key: "average_tipper_amt_count",
      label: "Average Tip Amount",
      textAlign: "right",
    },
    {
      key: "highest_tip_amount",
      label: "Highest Tip Amount",
      textAlign: "right",
    },
    {
      key: "netTipAmount",
      label: "Net Tip Amount",
    },
    {
      key: "total_number_of_tips",
      label: "Total Number of Tips",
    },
  ];

  const [countData, setCountData] = useState<any>(initialCountData);

  /**
   * @Array :- for action button dropdown values and passes to React table component
   */
  const actionButtonOptions =
    LocalData?.role_id === 3
      ? [
        {
          name: "View Property Info",
          icon: <IoEyeOutline />,
          onClick: (item: any) => {
            navigate(
              uuid
                ? "/" +
                uuid +
                `/manage-properties/view-property/${item.propertyUuid}`
                : `/manage-properties/view-property/${item.propertyUuid}`
            );
          },
        },
      ]
      : [
        {
          name: "View Property Info",
          icon: <IoEyeOutline />,
          onClick: (item: any) => {
            navigate(
              uuid
                ? "/" +
                uuid +
                `/manage-properties/view-property/${item.propertyUuid}`
                : `/manage-properties/view-property/${item.propertyUuid}`
            );
          },
        },
        {
          name: "View Tip Transactions",
          icon: <IoEyeOutline />,
          onClick: (item: any) => {
            navigate(urlForAdmin(), {
              state: {
                uuid: item?.propertyUuid,
                name: item?.name,
              },
            });
          },
        },
      ];

  /** Handle modal states */
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  /** Api definition */
  const [countApi, { response: countResponse, error: countError }] =
    useFetch("/dashboard/count");

  /** user dashboard data */
  // const [
  //   getDashBoardData,
  //   {
  //     response: dashBoardDataResponse,
  //     error: dashBoardDataError,
  //     loading: dashBoardDataLoading,
  //   },
  // ] = useFetch("/dashboard/all-tips", {
  //   method: "POST",
  // });

  const [
    getDashBoardData,
    {
      response: dashBoardDataResponse,
      error: dashBoardDataError,
      loading: dashBoardDataLoading,
    },
  ] = useFetch("/dashboard/property-tips", {
    method: "POST",
  });

  useEffect(() => {
    if (dashBoardDataResponse) {
      setTotalRecords(dashBoardDataResponse.data?.totalRecords);
      const updatedList = dashBoardDataResponse.data?.list
        ?.filter((item: any) => item?.property_details !== null)
        .map((item: any) => ({
          propertyId: item?.property_id,
          propertyUuid: item?.property_uuid,
          name: item?.property_name,
          netTipAmount: item?.total_net_tip_amount?.toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
          }),
          average_tipper_amt_count:
            item?.average_tipper_amt_count?.toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
            }),
          highest_tip_amount: item?.highest_tip_amount?.toLocaleString(
            "en-US",
            {
              style: "currency",
              currency: "USD",
            }
          ),
          total_number_of_tips: item?.total_number_of_tips,
        }));
      setDashboardData(updatedList);
    }
    if (dashBoardDataError) {
      showToast(dashBoardDataError.message as string, "error");
    }
  }, [dashBoardDataResponse, dashBoardDataError]);
  useEffect(() => {
    const timer = setTimeout(() => {
      try {
        getDashBoardData({
          start: (currentPage - 1) * pageLimit,
          limit: pageLimit,
          // startDate: null,
          // endDate: null,
          organization_uuid: null,
          // sortedBy: null,
          search: searchValue,
        });
      } catch (e: any) {
        notifyBugsnagError(e, {
          api: "getDashBoardData",
        });
      }
    }, 500);
    return () => clearTimeout(timer);
  }, [searchValue, currentPage, pageLimit]);

  /** Call countApi */
  useEffect(() => {
    countApi();
  }, []);

  /** Handle countApi response */
  useEffect(() => {
    if (countResponse) {
      const responseData = countResponse.data;
      const updatedData = countData.map((item: any) => ({
        ...item,
        amount: Math.round(responseData[item.key]),
        avg_amount: responseData?.average_tip_amount,
      }));

      setCountData(updatedData);
    }
    if (countError) {
      showToast(countError.message as string, "error");
    }
  }, [countResponse, countError]);

  const handleReturnBack = () => {
    loginAsAdminApi();
  };

  useEffect(() => {
    if (loginAsAdminRes) {
      window.location.replace(
        `${process.env.REACT_APP_BASE_URL_ADMIN}/organizations/view/${uuid}`
      );
      clearUserLocalData(`${uuid}`);
    }
    if (loginAsAdminErr) {
      showToast(loginAsAdminErr.message as string, "error");
    }
  }, [loginAsAdminRes, loginAsAdminErr]);

  return (
    <DashboardLayout>
      <div className={styles.dash_page}>
        <Container>
          <Row>
            {countResponse?.data?.stripe_status === 0 ||
              countResponse?.data?.stripe_status === 4 ||
              countResponse?.data?.stripe_status === 5 ? (
              <SetUpSuccess status={countResponse?.data?.stripe_status} />
            ) : countResponse?.data?.stripe_status === 3 ? (
              <SetUpError />
            ) : (
              ""
            )}
            <Col lg={6}>
              <PageHeading
                heading="Dashboard"
                subHeading="Here is the information about your organization."
              />
            </Col>
            {(LocalData?.admin_uuid || data?.admin_uuid) && (
              <Col
                lg={6}
                className="d-flex justify-content-end align-items-center"
              >
                <ThemeButton
                  onClick={handleReturnBack}
                  loading={loginAsAdminLoading}
                >
                  Return to Your Account
                </ThemeButton>
              </Col>
            )}
          </Row>

          {LocalData?.role_id === 3 && (
            <div className={styles.cardsWrap}>
              {countData.map((item: any) => (
                <Link
                  to={item.link}
                  className={styles.card_outer}
                  style={{
                    textDecoration: "none",
                    cursor: "pointer",
                  }}
                >
                  <div key={item.key} className={styles.card}>
                    <div className={styles.icon}>{item.icon}</div>
                    <div className={styles.content}>
                      <div className={styles.title}>{item.title}</div>
                    </div>
                  </div>
                </Link>
              ))}
            </div>
          )}

          {LocalData?.role_id === 2 && (
            <div className={styles.cardsWrap3}>
              {countData.map((item: any) => (
                <div
                  className={styles.card_outer}
                  key={item.key}
                  onClick={item.onclick}
                  style={{ cursor: "pointer" }}
                >
                  <div
                    className={`${styles.card} ${item.key === "average_tip_amount"
                      ? styles.revenueCard
                      : ""
                      }`}
                  >
                    <div className={styles.icon}>{item.icon}</div>
                    <div className={styles.amount}>
                      {item.key === "totalTipperAmt"
                        ? `$${item.amount?.toLocaleString("en-US", {
                          // style: "currency",
                          // currency: "USD",
                        })}`
                        : item.key === "average_tip_amount"
                          ? item?.avg_amount?.toLocaleString("en-US", {
                            style: "currency",
                            currency: "USD",
                          })
                          : item.amount?.toLocaleString("en-US")}
                    </div>
                    <div className={styles.title}>{item.title}</div>
                  </div>
                </div>
              ))}
            </div>
          )}

          <ReactTable
            headings={tableHeadings}
            data={dashbaordData || []}
            actionColumn
            statusColumn={false}
            actionButtonOptions={actionButtonOptions}
            currentPage={currentPage}
            itemPerPage={pageLimit}
            totalItems={totalRecords}
            pageLimit={pageLimit}
            setPerPageLimit={setPageLimit}
            setCurrentPage={setCurrentPage}
            pagination={true}
            loading={!dashbaordData || dashBoardDataLoading}
          >
            <div
              className={styles.dashboardHeadings}
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                padding: "20px 10px",
              }}
            >
              <h2>Property Tips</h2>
              <div className={styles.search}>
                <div className={styles.searchField}>
                  <InputField
                    name="search_organization_name"
                    icon={searchIcon}
                    placeholder="Search by Property Name"
                    value={searchValue}
                    onChange={(e) => {
                      setCurrentPage(1);
                      setSearchValue(e.target.value);
                    }}
                    autoComplete="off"
                  />
                  {searchValue ? (
                    <span
                      className={styles.crossIcon}
                      onClick={() => setSearchValue("")}
                    >
                      <RxCross1 color="#2549E8" />
                    </span>
                  ) : null}
                </div>
              </div>
            </div>
          </ReactTable>
        </Container>
      </div>
    </DashboardLayout>
  );
};
export default Dashboard;
