import { FormikProps } from "formik";
import { organizationAddEditType } from "../../../types/organization";
import FormSteps from "../../formSteps/FormSteps";
import ModalLayout from "../../layouts/ModalLayout";
import styles from "./addEditOrgnaization.module.scss";
import Step1 from "./steps/step1";
import Step2 from "./steps/step2";
import Step3 from "./steps/step3";
import LoadingBars from "../../theme/LoadingBars";

interface Iprops {
  formik: FormikProps<organizationAddEditType>;
  handleClose: () => void;
  show: boolean;
  title?: string;
  editModal: boolean;
  currentStep: number;
  setCurrentStep: (step: number) => void;
  loading: boolean;
  editLoading: boolean;
  setValidationSchema?: React.Dispatch<React.SetStateAction<any>>;
  editNameValue?: string;
  partnerDetails?: any;
  referredDetails?: any
}
const AddEditOrgnaizationModal = ({
  formik,
  handleClose,
  show,
  title,
  currentStep,
  setCurrentStep,
  loading,
  editLoading,
  editModal,
  setValidationSchema,
  editNameValue,
  partnerDetails,
  referredDetails
}: Iprops) => {
  const handleModalClose = () => {
    // Set the current step to 1 when closing the modal
    formik.resetForm();
    setCurrentStep(1);
    handleClose();
  };

  const steps = ["Organization", "Partner", "Configuration"];
  return (
    <ModalLayout
      title={title}
      show={show}
      dropDowns={currentStep === 2}
      size={655}
      handleToggle={handleModalClose}
    >
      {editLoading ? (
        <div className={styles.loadingWrap}>
          <LoadingBars color="#676d7a" />
        </div>
      ) : (
        <>
          <div className={styles.stepWrap}>
            <FormSteps currentStep={currentStep} steps={steps} />
          </div>

          {/* <form onSubmit={formik.handleSubmit}> */}
          <div className="">
            {currentStep === 1 && (
              <Step1
                editNameValue={editNameValue}
                show={show}
                editModal={editModal}
                formik={formik}
                handleClose={handleClose}
                setValidationSchema={setValidationSchema}
              />
            )}
            {currentStep === 2 && (
              <Step2
                editModal={editModal}
                formik={formik}
                currentStep={currentStep}
                setCurrentStep={setCurrentStep}
                partner_details={partnerDetails}
                referred_details={referredDetails}
              />
            )}
            {currentStep === 3 && (
              <Step3
                editModal={editModal}
                formik={formik}
                currentStep={currentStep}
                setCurrentStep={setCurrentStep}
                loading={loading}
              />
            )}
          </div>
          {/* </form> */}
        </>
      )}
    </ModalLayout>
  );
};

export default AddEditOrgnaizationModal;
